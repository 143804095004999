import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
import serialize from '../../utils/serialize';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  infoLicYear: [],
  infoLicMonth: [],
  infoLic: [],
  infoValue: {},
  qntCustomers: 0,
  infoVisitors: {},
  infoLicOrders: {}
};

const slice = createSlice({
  name: 'dashboardLic',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getInfoLicSuccess(state, action) {
      state.isLoading = false;
      state.infoLic = action.payload;
    },

    getInfoLicOrdersSuccess(state, action) {
      state.isLoading = false;
      state.infoLicOrders = action.payload;
    },

    getInfoLicYearSuccess(state, action) {
      state.isLoading = false;
      state.infoLicYear = action.payload;
    },

    getInfoLicMonthSuccess(state, action) {
      state.isLoading = false;
      state.infoLicMonth = action.payload;
    },
    getInfoValueSuccess(state, action) {
      state.isLoading = false;
      state.infoValue = action.payload;
    },
    getQntCustomersSuccess(state, action) {
      state.isLoading = false;
      state.qntCustomers = action.payload;
    },
    getQntVisitorsSuccess(state, action) {
      state.isLoading = false;
      state.infoVisitors = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getInfoLicYear(data) {
  return async (dispatch) => {
    try {
      data = serialize(data);
      console.log(data)
      const response = await axios.get(`${api}/info-lic-year?${data}`);
      dispatch(slice.actions.getInfoLicYearSuccess(response.data.result));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getInfoLicMonth(data) {
  return async (dispatch) => {
    try {
      data = serialize(data);
      const response = await axios.get(`${api}/info-lic-month?${data}`);
      dispatch(slice.actions.getInfoLicMonthSuccess(response.data.results));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getInfoLic(data) {
  return async (dispatch) => {
    try {
      data = serialize(data);
      const response = await axios.get(`${api}/info-dashboard-lic?${data}`);
      dispatch(slice.actions.getInfoLicSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}


export function getInfoLicOrders(data) {
  return async (dispatch) => {
    try {
      data = serialize(data);
      const response = await axios.get(`${api}/info-lic-orders?${data}`);
      dispatch(slice.actions.getInfoLicOrdersSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getInfoValue(data) {
  return async (dispatch) => {
    try {
      data = serialize(data);
      const response = await axios.get(`${api}/info-dashboard-values-lic?${data}`);
      dispatch(slice.actions.getInfoValueSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getQntCustomers(data) {
  return async (dispatch) => {
    try {
      data = serialize(data);
      const response = await axios.get(`${api}/info-dashboard-customers-lic?${data}`);
      dispatch(slice.actions.getQntCustomersSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getQntVisitors(data) {
  return async (dispatch) => {
    try {
      data = serialize(data);
      const response = await axios.get(`${api}/visitors?${data}`);
      dispatch(slice.actions.getQntVisitorsSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
