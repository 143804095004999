import { Helmet } from 'react-helmet-async';
import { googleAnalyticsConfig } from '../config';

// ----------------------------------------------------------------------

const GA_MEASUREMENT_ID = googleAnalyticsConfig;

export default function Tawk() {
  return (
    <Helmet>
      <script>
        {`
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/6187ee7d6bb0760a494182d7/1fjtfn6t1';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();
      `}
      </script>
    </Helmet>
  );
}
