import { useEffect } from 'react';
// material
import { styled } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getMunicipios } from '../../../redux/slices/licensed';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import useResponsive from '../../../hooks/useResponsive';

import Page from '../../../components/Page';
import RestaurantSingleNewFormUnauth from '../../../components/_dashboard/restaurant/RestaurantSingleNewFormUnauth';
import { getCategoryList } from '../../../redux/slices/category';
import Logo from '../../../components/Logo';



const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  flexDirection: 'column',
  paddingTop: '2rem'
}));

// ----------------------------------------------------------------------

export default function RestaurantCreateUnauth() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { municipiosList } = useSelector((state) => state.licensed);
  const { categoryList } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(getCategoryList('', true));
    dispatch(getMunicipios());
  }, [dispatch]);

  const isDesktop = useResponsive('up', 'md');
  const Heading = () => {
    const headingStyle = {
      color: '#000',
      fontSize: isDesktop ? '22px' : '14px',
    };
    return (
      <div>
        <h1 style={headingStyle}>Solicitar cadastro de novo estabelecimento:</h1>
      </div>
    );
  };

  return (
    <RootStyle title="Solicitar cadastro de novo estabelecimento | Jão Delivery">
      <Container maxWidth='xl'>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '15px 30px 15px 30px', borderRadius: '15px 15px 0px 0px', background: '#ffcc07' }}>
          <Heading />
          <img
            alt="Logo Jão Delivery"
            src='/static/logo/logoNew.png'
            width={80} />
        </div>

        <RestaurantSingleNewFormUnauth
          categoryList={categoryList}
          municipiosList={municipiosList}
        />
        <br />
      </Container>
    </RootStyle>
  );
}
