import { Icon } from '@iconify/react';
import { MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';

const YayButton = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//embed.yayforms.link/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (<a data-yf-popup="J7dkqPV"
    data-yf-size="100" style={{ textDecoration: 'none', color: 'inherit' }}>
    <MenuItem
      key="Portal de ideias"
      sx={{ typography: 'body2', py: 1, px: 2.5 }}
    >
      <Icon style={{
        width: 24,
        marginRight: 15,
        height: 24
      }} icon="emojione-monotone:light-bulb" />
      Portal de ideias
    </MenuItem>
  </a>);
};

export default YayButton;
