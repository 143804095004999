import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  marketingList: [],
  responsePost: null,
  marketing: {}
};

const slice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getMarketingSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    deleteMarketing(state, action) {
      const deleteMarketing = filter(state.marketingList, (category) => category._id !== action.payload);
      state.marketingList = deleteMarketing;
    },

    getMarketingListSuccess(state, action) {
      state.isLoading = false;
      state.marketingList = action.payload;
    },

    getMarketing(state, action) {
      state.isLoading = false;
      state.marketing = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getMarketingList(type = '') {
  let filter = '';
  if (type) filter = `?parent=${type}`;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/marketing${filter}`);
      dispatch(slice.actions.getMarketingListSuccess(response.data.contents));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMarketing(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/marketing/${id}`);
      dispatch(slice.actions.getMarketing(response.data.content));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeMarketing(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/marketing`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateMarketing(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/marketing`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteMarketing(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/marketing/${newId}`);
      dispatch(slice.actions.deleteMarketing(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
