import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  result: []
};

const slice = createSlice({
  name: 'whatsApp',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    qrcode(state, action) {
      state.isLoading = false;
      state.result = action.payload;
    }

    
  }
});

// Reducer
export default slice.reducer;

export function connectInstance(user, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/whatsapp/connect/${user}/${id}`);
      dispatch(slice.actions.qrcode(response.data.message.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function disconnectInstance(user, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/whatsapp/disconnect/${user}/${id}`);
      dispatch(slice.actions.qrcode(response.data.message.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function paymentWhatsapp(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/whatsapp/subscription`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

