import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  logList: []
};

const slice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getLogListSuccess(state, action) {
      state.isLoading = false;
      state.logList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getLogList(_id, type, nameFilter, startDate, endDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let filters = `_id=${_id}&type=${type}`
    if (nameFilter) filters += `&name=${nameFilter}`
    if (startDate) filters += `&startDate=${startDate}`
    if (endDate) filters += `&endDate=${endDate}`
    try {
      const response = await axios.get(`${api}/logs?${filters}`);
      dispatch(slice.actions.getLogListSuccess(response.data.logs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
