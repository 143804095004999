import { styled, Button, Typography, Link } from '@mui/material';
import { Icon } from '@iconify/react';
import useAuth from '../../hooks/useAuth';

const StyledButton = styled(Button)({
  display: 'flex',
  borderRadius: 12,
  backgroundColor: '#00AB55',
  marginTop: 15,
  width: '100%',
  padding: '12px 24px',
  boxShadow: '0px 4px 6px rgba(7, 245, 126, 0.24)',
  transition: 'all 250ms',
  '&:hover': {
    transform: 'translateY(-2px)',
    backgroundColor: '#00AB55',
    boxShadow: '0px 6px 12px rgba(0,171,85,0.24)'
  }
});

const StyledIcon = styled(Icon)({
  fontSize: 22,
  color: '#fff'
});

const WhatsAppButton = () => {
  const { user } = useAuth();
  const whatsappMessage = `Olá! Eu sou ${user?.name}. Estou precisando de ajuda.`;

  return (
    <Link
      href={`https://wa.me/5569981181433?text=${encodeURIComponent(whatsappMessage)}`}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
      aria-label="Entre em contato via WhatsApp"
    >
      <StyledButton variant="contained" startIcon={<StyledIcon icon="ic:baseline-whatsapp" />}>
        <Typography variant="button" fontWeight={600}>
          Precisa de Suporte?
        </Typography>
      </StyledButton>
    </Link>
  );
};

export default WhatsAppButton;
