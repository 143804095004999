import PropTypes from 'prop-types';
// @mui
import { Stack, Typography, Box, CircularProgress, Card } from '@mui/material';
// utils
import { fShortenNumber, fMoney } from '../../../utils/formatNumber';
// components
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------

ReportAnalytic.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  percent: PropTypes.number,
  price: PropTypes.number,
  total: PropTypes.number,
  text: PropTypes.string
};

export default function ReportAnalytic({ title, total, icon, color, percent, price, text = 'pedidos' }) {
  return (
    <Card style={{
      borderRadius: 8,
      boxShadow: "rgba(0, 0, 0, 0.11) 0px 1px 5px",
      backgroundColor: "#fff",
      width: "250px",
      marginRight: 10
    }}>

      <Stack
        style={{
          padding: "10px",
          display: "flex",
          flexDirection: "row",
        }}>

        <Stack
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 15px",
          }}
        >
          <Iconify icon={icon} style={{ width: 24, height: 24, color: 'black' }} />
        </Stack>

        <Stack style={{ flexGrow: 1 }}>
          <Typography sx={{ color: 'gray', fontWeight: '600', fontSize: 17 }}>
            {title}
          </Typography>

          {price !== undefined && (
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
              }}
            >
              R${fMoney(price)}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        style={{
          backgroundColor: "#f7f7f7",
          padding: "10px",
          borderRadius: "0 0 5px 5px",
          marginTop: "auto",
        }}
      >
        {total >= 0 && (
          <Typography
            variant="body2"
            sx={{
              color: 'gray',
              fontWeight: '600',
              fontSize: 15
            }}
          >
            {fShortenNumber(total)} pedidos
          </Typography>
        )}
      </Stack>
    </Card>



  );
}
