import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack5';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByLatLng,
  geocodeByAddress
} from 'react-google-places-autocomplete';
// material
import { LoadingButton } from '@material-ui/lab';
import jsonToFormData from '@ajoelp/json-to-formdata';
import axios from 'axios';
import { Icon } from '@iconify/react';
import { IconButton, MenuItem } from '@mui/material';
import {
  Alert,
  Box,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Card,
  Stack,
  TextField,
  Typography,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  Skeleton
} from '@material-ui/core';
import Grid from '@mui/material/Unstable_Grid2';
// utils
import { fData } from '../../../utils/formatNumber';
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
//
import Label from '../../Label';
import { UploadAvatar } from '../../upload';
import { useDispatch, useSelector } from '../../../redux/store';
import { storeRestaurant, updateRestaurant, getRestaurantInfo, getBrothers } from '../../../redux/slices/restaurant';
import { api, urlEcommerce } from '../../../config';
import {
  contentSection,
  titleSection,
  containerSection,
  uploadLabel,
  inputFieldLabel,
  inputField,
  inputSelectField
} from '../../../theme/restaurantStyles';
// ----------------------------------------------------------------------

RestaurantNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentRestaurant: PropTypes.object,
  municipios: PropTypes.array,
  uf: PropTypes.string,
  categoryList: PropTypes.array,
  bro: PropTypes.array
};

export default function RestaurantNewForm({ isEdit, currentRestaurant, municipios, uf, categoryList }) {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { restaurantInfo, brothersList } = useSelector((state) => state.restaurant);
  const [cidades] = useState(municipios);
  const [state] = useState(uf);
  const [image, setImage] = useState('');
  const [image2, setImage2] = useState('');
  const [brothers, setBrothers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [loadingMyLocation, setLoadingMyLocation] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const getCurrentLocation = () => {
    setLoadingMyLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLat(latitude);
          setLng(longitude);
          updateAddress(latitude, longitude);
        },
        (error) => {
          console.error('Erro ao obter a localização:', error);
        }
      );
    } else {
      console.error('Geolocation não é suportado pelo navegador.');
    }
  };

  useEffect(() => {
    if (!selectedAddress) return;
    geocodeByPlaceId(selectedAddress.value.place_id)
      .then((results) => {
        setLat(results[0].geometry.location.lat());
        setLng(results[0].geometry.location.lng());
        updateAddress(results[0].geometry.location.lat(), results[0].geometry.location.lng());
        formik.setFieldValue('address', results[0].formatted_address);
        formik.setFieldValue(
          'district',
          results[0].address_components.find((e) => e.types.includes('sublocality'))?.long_name
        );
        formik.setFieldValue(
          'num',
          results[0].address_components.find((e) => e.types.includes('street_number'))?.long_name
        );
      })
      .catch((error) => console.error(error));
  }, [selectedAddress]);

  const updateAddress = (lat, lng) => {
    geocodeByLatLng({ lat, lng })
      .then((results) => {
        formik.setFieldValue('lat', lat);
        formik.setFieldValue('long', lng);
        setLoadingMyLocation(false);
        setOpen(false);
      })
      .catch((error) => console.error(error));
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    dispatch(getRestaurantInfo());
    if (categoryList) {
      let type = 2;
      if (currentRestaurant?.type) type = currentRestaurant?.type;
      setCategories(categoryList.filter((cat) => cat.parent === type));
    }
    // eslint-disable-next-line
  }, [categoryList]);

  useEffect(() => {
    if (currentRestaurant && brothers.length === 0) {
      dispatch(getBrothers(currentRestaurant._id));
      const bro = [];
      brothersList.forEach((elem) => {
        if (elem._id !== currentRestaurant._id) {
          bro.push({
            label: elem.name,
            id: elem._id
          });
        }
      });
      setBrothers(bro);
    }
  }, [currentRestaurant, brothersList]);

  let validation = {};

  if (isEdit) {
    validation = {
      name: Yup.string().required('Nome é obrigatório'),
      address: Yup.string().required('Endereço é obrigatório')
    };
  } else {
    validation = {
      name: Yup.string().required('Nome é obrigatório'),
      address: Yup.string().required('Endereço é obrigatório'),
      // category: Yup.string().oneOf(['0', 0, false], 'Escolha uma categoria!').required(),
      nameResp: Yup.string().required('Nome do responsável é obrigatório'),
      emailResp: Yup.string().required('E-mail do responsável é obrigatório').email('E-mail inválido'),
      commission: Yup.number().required('Comissão é obrigatório')
    };
  }

  const NewRestaurantSchema = Yup.object().shape(validation);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentRestaurant?.name || '',
      description: currentRestaurant?.description || '',
      city: currentRestaurant?.local.city || cidades[0]?.codigo_ibge,
      category: currentRestaurant?.category || '',
      type: currentRestaurant?.type || 2,
      tel1: currentRestaurant?.tel[0] || '',
      tel2: currentRestaurant?.tel[1] || '',
      boxdelivery_code: currentRestaurant?.boxdelivery_code || '',
      fuse: currentRestaurant?.fuse || 'America/Sao_Paulo',
      address: currentRestaurant?.address || '',
      num: currentRestaurant?.num || '',
      slug: currentRestaurant?.slug || '',
      deliveryMan: currentRestaurant?.deliveryMan || false,
      district: currentRestaurant?.district || '',
      logo:
        currentRestaurant?.fileName !== undefined
          ? `${api}/files/restaurants/logos/${currentRestaurant.fileName}`
          : null,
      banner:
        currentRestaurant?.fileNameBanner !== undefined
          ? `${api}/files/restaurants/banners/${currentRestaurant.fileNameBanner}`
          : null,
      emailResp: '',
      nameResp: '',
      commission: currentRestaurant?.commission || 10,
      lat: currentRestaurant?.coord.lat || '',
      long: currentRestaurant?.coord.long || ''
    },
    validationSchema: NewRestaurantSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        let result;
        if (state) {
          values.local = { uf: state, city: values.city };
        }
        values.coord = { lat: values.lat, long: values.long };
        values.tel = [];
        values.tel[0] = values.tel1;
        values.tel[1] = values.tel2;
        /* delete values.tel1;
        delete values.tel2;
        delete values.lat;
        delete values.long; */
        values.brother = brothers;
        if (!isEdit) {
          values.user = {
            name: values.nameResp,
            email: values.emailResp
          };
        }
        const formData = jsonToFormData(values);
        if (image) formData.append('image', image);
        if (image2) formData.append('imageBanner', image2);
        if (isEdit) {
          formData.append('_id', currentRestaurant?._id);
          result = await dispatch(updateRestaurant(formData));
        } else {
          formData.append('adminCreated', user.type <= 2);
          result = await dispatch(storeRestaurant(formData));
          // await dispatch(storeShopkeeper(user));
        }

        if (result.error) {
          setSubmitting(false);
          setErrors({ afterSubmit: result.message });
          window.scrollTo(0, 0);
        } else {
          if (!isEdit) {
            resetForm();
            setSubmitting(false);
          }
          enqueueSnackbar(result.message, { variant: 'success' });
          // navigate(PATH_DASHBOARD.restaurant.list);
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue('logo', {
          ...file,
          preview: URL.createObjectURL(file)
        });
        setImage(file);
      }
    },
    [setFieldValue]
  );

  const handleDrop2 = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue('banner', {
          ...file,
          preview: URL.createObjectURL(file)
        });
        setImage2(file);
      }
    },
    [setFieldValue]
  );

  const changeParent = (event) => {
    event.preventDefault();
    formik.setFieldValue('type', event.target.value);
    formik.setFieldValue('category', false);
    // eslint-disable-next-line eqeqeq
    const result = categoryList.filter((cat) => cat.parent == event.target.value);
    setCategories(result);
  };

  async function getCoord() {
    try {
      const e = document.getElementById('city');
      const city = e.options[e.selectedIndex].text;
      const infos = `${values.address} ${values.num}, ${values.district}, ${city}-${uf}`;
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${infos}&key=AIzaSyD7xGSICQrE46kaUNE9ONguEVmI-xpguyM`,
        {
          transformRequest: (data, headers) => {
            delete headers.common.Authorization;
            return data;
          }
        }
      );
      formik.setFieldValue('lat', response.data.results[0].geometry.location.lat);
      formik.setFieldValue('long', response.data.results[0].geometry.location.lng);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  }

  const optionsBrothers = [];
  restaurantInfo.forEach((rest) => {
    if (rest._id !== currentRestaurant?._id) {
      optionsBrothers.push({
        label: rest.name,
        id: rest._id
      });
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        {errors.afterSubmit && (
          <Alert sx={{ mb: 2 }} severity="error">
            {errors.afterSubmit}
          </Alert>
        )}
        <Grid container spacing={3}>
          {!currentRestaurant && isEdit ? (
            <>
              {/* Skeletons enquanto currentRestaurant não está carregado */}
              <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" height={397} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" height={397} />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Skeleton variant="rectangular" width={707} height={56} sx={{ mb: 3 }} />
                  <Skeleton variant="rectangular" width={707} height={56} sx={{ mb: 3 }} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Skeleton variant="rectangular" width={707} height={56} sx={{ mb: 3 }} />
                  <Skeleton variant="rectangular" width={707} height={56} sx={{ mb: 3 }} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Skeleton variant="rectangular" width={707} height={56} sx={{ mb: 3 }} />
                  <Skeleton variant="rectangular" width={707} height={56} sx={{ mb: 3 }} />
                </Box>
                <Skeleton variant="rectangular" height={56} sx={{ mb: 3 }} />
                <Skeleton variant="rectangular" height={56} sx={{ mb: 3 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Skeleton variant="rectangular" width={707} height={56} sx={{ mb: 3 }} />
                  <Skeleton variant="rectangular" width={707} height={56} sx={{ mb: 3 }} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Skeleton variant="rectangular" width={472} height={56} sx={{ mb: 3 }} />
                  <Skeleton variant="rectangular" width={472} height={56} sx={{ mb: 3 }} />
                  <Skeleton variant="rectangular" width={472} height={56} sx={{ mb: 3 }} />
                </Box>
                <Skeleton variant="rectangular" height={56} sx={{ mb: 3 }} />
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <Skeleton variant="rounded" width={71} height={36} />
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Label>Logo</Label>
                <Card sx={{ py: 10, px: 3 }}>
                  <Box sx={{ mb: 5 }}>
                    <UploadAvatar
                      accept="image/*"
                      file={values.logo}
                      maxSize={1048576}
                      onDrop={handleDrop}
                      error={Boolean(touched.logo && errors.logo)}
                      caption={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary'
                          }}
                        >
                          Permitido *.jpeg, *.jpg, *.png
                          <br /> tamanho máximo {fData(1048576)}
                        </Typography>
                      }
                    />
                    <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                      {touched.logo && errors.logo}
                    </FormHelperText>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Label>Banner</Label>
                <Card sx={{ py: 10, px: 3 }}>
                  <Box sx={{ mb: 5 }}>
                    <UploadAvatar
                      accept="image/*"
                      file={values.banner}
                      maxSize={1048576}
                      onDrop={handleDrop2}
                      error={Boolean(touched.banner && errors.banner)}
                      caption={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary'
                          }}
                        >
                          Permitido *.jpeg, *.jpg, *.png
                          <br /> tamanho máximo {fData(1048576)}
                        </Typography>
                      }
                    />
                    <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                      {touched.logo && errors.logo}
                    </FormHelperText>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Nome"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                      <TextField
                        fullWidth
                        label="Descrição"
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>Tipo</InputLabel>
                        <Select native label="Tipo" {...getFieldProps('type')} onChange={(e) => changeParent(e)}>
                          <option value={1}>Diversos</option>
                          <option value={2}>Bebida</option>
                        </Select>
                      </FormControl>

                      <FormControl fullWidth>
                        <InputLabel>Categoria</InputLabel>
                        <Select native label="Categoria" {...getFieldProps('category')}>
                          <option key={0} value={false}>
                            Selecione uma categoria
                          </option>
                          {categories
                            ? categories.map((cat) => (
                                <option key={cat._id} value={cat._id}>
                                  {cat.name}
                                </option>
                              ))
                            : 'Carregando...'}
                        </Select>
                      </FormControl>
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Telefone 1"
                        {...getFieldProps('tel1')}
                        error={Boolean(touched.tel1 && errors.tel1)}
                        helperText={touched.tel1 && errors.tel1}
                      />
                      <TextField
                        fullWidth
                        label="Telefone 2"
                        {...getFieldProps('tel2')}
                        error={Boolean(touched.tel2 && errors.tel2)}
                        helperText={touched.tel2 && errors.tel2}
                      />
                    </Stack>
                    {state ? (
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <FormControl fullWidth>
                          <InputLabel>Cidade</InputLabel>
                          <Select native label="Cidade" {...getFieldProps('city')} id="city">
                            {cidades
                              ? cidades.map((loc) => (
                                  <option key={loc.codigo_ibge} value={loc.codigo_ibge}>
                                    {loc.nome_municipio}
                                  </option>
                                ))
                              : 'Carregando...'}
                          </Select>
                        </FormControl>
                      </Stack>
                    ) : (
                      <></>
                    )}
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>Fuso Horário</InputLabel>
                        <Select native label="Fuso Horário" {...getFieldProps('fuse')}>
                          <option value="America/Sao_Paulo">Horário de Brasília (GMT-3)</option>
                          <option value="America/Noronha">Fernando de Noronha (GMT-2)</option>
                          <option value="America/Manaus">RR, RO, AM, MT e MS (GMT-4)</option>
                          <option value="America/Rio_Branco">Acre e Oeste do AM (GMT-5)</option>
                        </Select>
                      </FormControl>
                      <TextField
                        style={{ display: 'none' }}
                        fullWidth
                        label="Bairro"
                        {...getFieldProps('district')}
                        error={Boolean(touched.district && errors.district)}
                        helperText={touched.district && errors.district}
                      />
                    </Stack>
                    {!isEdit ? (
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Nome Responsável"
                          {...getFieldProps('nameResp')}
                          error={Boolean(touched.nameResp && errors.nameResp)}
                          helperText={touched.nameResp && errors.nameResp}
                        />
                        {!isEdit ? (
                          <TextField
                            fullWidth
                            type="email"
                            label="E-mail responsável"
                            {...getFieldProps('emailResp')}
                            error={Boolean(touched.emailResp && errors.emailResp)}
                            helperText={touched.emailResp && errors.emailResp}
                          />
                        ) : (
                          ''
                        )}
                      </Stack>
                    ) : (
                      ''
                    )}
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      {user.type <= 2 ? (
                        <TextField
                          fullWidth
                          type="number"
                          label="Comissão"
                          {...getFieldProps('commission')}
                          error={Boolean(touched.commission && errors.commission)}
                          helperText={touched.commission && errors.commission}
                        />
                      ) : (
                        <FormControl fullWidth>
                          <InputLabel>Comissão (%)</InputLabel>
                          <Select native label="Comissão" {...getFieldProps('commission')}>
                            <option value="0">Valor fixo</option>
                            <option value="8">8%</option>
                            <option value="9">9%</option>
                            <option value="10">10%</option>
                            <option value="11">11%</option>
                            <option value="12">12%</option>
                          </Select>
                        </FormControl>
                      )}

                      <FormControl fullWidth>
                        <InputLabel>Entregadores</InputLabel>
                        <Select native label="Entregadores" {...getFieldProps('deliveryMan')}>
                          <option value={false}>Não</option>
                          <option value>Sim</option>
                        </Select>
                      </FormControl>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <Button fullWidth onClick={handleClickOpen}>
                        Selecionar seu endereço
                      </Button>
                      <TextField
                        onClick={handleClickOpen}
                        fullWidth
                        label="Endereço selecionado"
                        {...getFieldProps('address')}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                        InputProps={{
                          readOnly: true,
                          style: { border: 'none !important' }
                        }}
                        variant="standard"
                      />
                      <TextField
                        fullWidth
                        label="Número"
                        {...getFieldProps('num')}
                        error={Boolean(touched.num && errors.num)}
                        helperText={touched.num && errors.num}
                      />
                      <TextField
                        style={{ display: 'none' }}
                        fullWidth
                        label="Latitude"
                        placeholder="00.00000"
                        {...getFieldProps('lat')}
                        error={Boolean(touched.lat && errors.lat)}
                        helperText={touched.lat && errors.lat}
                      />
                      <TextField
                        style={{ display: 'none' }}
                        fullWidth
                        label="Longitude"
                        placeholder="00.00000"
                        {...getFieldProps('long')}
                        error={Boolean(touched.long && errors.long)}
                        helperText={touched.long && errors.long}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <Autocomplete
                        fullWidth
                        multiple
                        value={isEdit ? brothers.filter((elem) => elem._id !== currentRestaurant?._id) : brothers}
                        onChange={(e, newValue) => {
                          setBrothers(newValue);
                        }}
                        options={optionsBrothers}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField label="Lojas integradas" {...params} />}
                      />
                    </Stack>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {!isEdit ? 'Cadastrar' : 'Salvar'}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </Form>

      <div>
        <Dialog
          open={open}
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Digite seu endereço correto</DialogTitle>
          <DialogContent style={{ height: '300px' }}>
            <DialogContentText id="alert-dialog-description">
              <Box sx={{ mt: 1, mb: 2 }}>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyD7xGSICQrE46kaUNE9ONguEVmI-xpguyM"
                  selectProps={{
                    selectedAddress,
                    onChange: setSelectedAddress,
                    value: selectedAddress,
                    placeholder: 'Digite o  endereço de entrega',
                    noOptionsMessage: () => 'Nenhum endereço encontrado',
                    loadingMessage: () => 'Pesquisando...'
                  }}
                  defaultProps={{}}
                />
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  style={{ color: 'black', marginTop: '10px', backgroundColor: '#ffce00' }}
                  onClick={getCurrentLocation}
                >
                  <span style={{ marginLeft: '5px' }}>
                    {!loadingMyLocation ? 'Usar Localização Atual' : 'Carregando...'}
                  </span>
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </FormikProvider>
  );
}
