import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  contractList: [],
  contractListUser: [],
  contractListUserPayment: [],
  responsePost: null,
  contract: {}
};

const slice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    // START LOADING 
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getContractsSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    deleteContract(state, action) {
      const deleteContract = filter(state.contractList, (category) => category._id !== action.payload);
      state.contractList = deleteContract;
    },

    getContractListSuccess(state, action) {
      state.isLoading = false;
      state.contractList = action.payload;
    },

    getContract(state, action) {
      state.isLoading = false;
      state.contract = action.payload;
    },
    
    getContractUser(state, action) {
      state.isLoading = false;
      state.contractListUser = action.payload;
    },

    getContractUserPayment(state, action) {
      state.isLoading = false;
      state.contractListUserPayment = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getContractList(type = '') {
  let filter = '';
  if (type) filter = `?parent=${type}`;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/contracts${filter}`);
      dispatch(slice.actions.getContractListSuccess(response.data.contracts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
} 

export function getContractListUser(id = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/contracts-user-list/${id}`);
      dispatch(slice.actions.getContractListSuccess(response.data.contracts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }; 
} 

export function getContractUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/contracts-user/${id}`);
      dispatch(slice.actions.getContractUser(response.data.contract));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContractUserPayment(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/contracts-user-payment/${id}`);
      dispatch(slice.actions.getContractUserPayment(response.data.contract));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getContract(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/contracts/${id}`);
      dispatch(slice.actions.getContract(response.data.contract));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeContract(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/contracts`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateContract(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/contracts`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteContract(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/contracts/${newId}`);
      dispatch(slice.actions.deleteContract(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
