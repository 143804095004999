export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cognitoConfig = {
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

export const googleAnalyticsConfig = 'G-ZYZBHXY72S';

// eslint-disable-next-line prettier/prettier 
export const api = process.env.NODE_ENV === 'production' ? 'https://api.jaodelivery.com' : 'http://localhost:8080';
export const newApi = process.env.NODE_ENV === 'production' ? 'https://api.jao.app' : 'http://localhost:8000';
// http://localhost:8080 https://api.jaodelivery.com s
export const urlWebsocket =
    process.env.NODE_ENV === 'production' ? 'https://websocket.jaodelivery.com' : 'http://localhost:4545';

export const urlEcommerce = 'https://e.jaodelivery.com/';
