import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api, newApi } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  customersList: [],
  customers: {},
  customer: [],
  responsePost: null
};

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCustomersSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    deleteCustomer(state, action) {
      const deleteCustomer = filter(state.customersList, (category) => category._id !== action.payload);
      console.log(deleteCustomer);
      state.customersList = deleteCustomer;
    },

    getCustomerListSuccess(state, action) {
      state.isLoading = false;
      state.customersList = action.payload;
    },

    getCustomer(state, action) {
      state.isLoading = false;
      state.customer = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getCustomerList(page = 1, limit = 10, name = '', filters = [], cities = [], start = '', end = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      filters = filters.toString();
      const response = await axios.get(`${newApi}/customers?page=${page}&limit=${limit}&name=${name}&filters=${filters}&cities=${cities}&start=${start}&end=${end}`);
      // dispatch(slice.actions.getCustomerListSuccess(response.data.customers));
      console.log(response.data);
      dispatch(slice.actions.getCustomerListSuccess(response.data.customers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomer(id) {
  return async (dispatch) => {
    // console.log(id)
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/customersPanel/${id}`);
      dispatch(slice.actions.getCustomer(response.data.user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeCustomer(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/customers`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateCustomer(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/customersPanel`, values);
      dispatch(slice.actions.getCustomer({}));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateWalletCustomer(values) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${api}/wallet`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCustomer(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/customers/${newId}`);
      dispatch(slice.actions.deleteCustomer(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
