import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api, newApi } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingModel: false,
  updateLoading: false,
  error: false,
  products: [],
  productList: [],
  product: {},
  productsModel: [],
  productModelList: [],
  categoriesModel: [],
  responsePost: null
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingModel(state) {
      state.isLoadingModel = true;
    },

    stopLoadingModel(state) {
      state.isLoadingModel = false;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    startUpdateLoading(state) {
      state.updateLoading = true;
    },

    stopUpdateLoading(state) {
      state.updateLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    getProductModelSuccess(state, action) {
      state.isLoadingModel = false;
      state.productModel = action.payload;
    },

    deleteProduct(state, action) {
      const deleteProduct = filter(state.productList, (category) => category._id !== action.payload);
      state.productList = deleteProduct;
    },

    deleteProductModel(state, action) {
      const deleteProductModel = filter(state.productModelList, (category) => category._id !== action.payload);
      state.productModelList = deleteProductModel;
    },

    updateProductReload(state, action) {
      const stateProductList = JSON.parse(JSON.stringify(state.productList));

      state.productList = stateProductList.map((product) => {
        if (product._id?.toString() === action.payload._id?.toString()) {
          const updatedProduct = action.payload;
          // updatedProduct.fileName = product.fileName;
          return updatedProduct;
        }
        return product;
      });

      state.product = {};
      state.isLoading = false;
    },

    updateProductModelReload(state, action) {
      const stateProductList = JSON.parse(JSON.stringify(state.productModelList));

      state.productModelList = stateProductList.map((product) => {
        if (product._id?.toString() === action.payload._id?.toString()) {
          const updatedProduct = action.payload;
          return {
            ...product,
            ...updatedProduct
          };
        }
        return product;
      });

      state.productModel = {};
      state.isLoadingModel = false;
    },


    getProductListSuccess(state, action) {
      state.isLoading = false;
      state.productList = action.payload.docs;
      state.productsTotal = action.payload.total;
    },

    getProductsModelListSuccess(state, action) {
      state.isLoadingModel = false;
      state.productModelList = action.payload.docs;
      state.productModelTotal = action.payload.totalDocs;
    },

    getProductsModelListSuccessOld(state, action) {
      state.isLoadingModel = false;
      state.productsModel = action.payload;
    },

    getCategoriesModelSuccess(state, action) {
      state.isLoading = false;
      state.categoriesModel = action.payload;
    },

    getProduct(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getProductList(restaurant = '', limit = 15, page = 1, search = '') {
  // let filter = `?limit=${limit}&page=${page}&search=${search}`;
  let filter = '';
  if (restaurant) filter += `?parent=${restaurant}`;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${newApi}/products${filter}`);
      dispatch(slice.actions.stopUpdateLoading())
      dispatch(
        slice.actions.getProductListSuccess({
          docs: response.data.docs,
          total: response.data.totalDocs,
          pagesTotal: response.data.totalPages,
          actualPage: response.data.page
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProductOrder(values) {
  return async (dispatch) => {
    try {
      const response = await axios.put(`${api}/product-order`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function storeProductModel(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingModel());
    try {
      const response = await axios.post(`${api}/productsModels`, values);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
}

export function updateProductModel(id, values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoadingModel());
    try {
      const response = await axios.put(`${api}/productsModels/${id}`, values);
      dispatch(slice.actions.getProductModelSuccess(response.data.productModel));
      dispatch(slice.actions.updateProductModelReload(response.data.productModel));
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
}

export function addProductByModel(values) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${api}/productsModels/add`, values);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
}

export function bulkAddProductsByModels(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/productsModels/bulkAdd`, values);
      dispatch(slice.actions.stopLoading())
      return response.data;
    } catch (error) {
      dispatch(slice.actions.stopLoading())
      return error.response.data;
    }
  };
}

export function getProductModelList(page, rowsPerPage, filterName, filterCategory, filter, filterBrand) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingModel());
    try {
      const response = await axios.get(`${api}/productsModels?page=${page}&limit=${rowsPerPage}&search=${filterName}&category=${filterCategory}&filter=${filter}${filterBrand ? `&brand=${filterBrand}` : ''}`);
      dispatch(slice.actions.getProductsModelListSuccess(response.data.productsModels));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductModel(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingModel());
    try {
      const response = await axios.get(`${api}/productsModels/${id}`);
      dispatch(slice.actions.getProductModelSuccess(response.data.productModel));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductModelListOld() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/product-model`);
      dispatch(slice.actions.getProductsModelListSuccessOld(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductModelListShop(page, category = '', search = '', brand = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/product-model-shop?page=${page}&category=${category}&search=${search}${brand ? `&brand=${brand}` : ''}`);
      dispatch(slice.actions.getProductsModelListSuccessOld(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCategoriesProductModel() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/categories-model`);
      dispatch(slice.actions.getCategoriesModelSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProduct(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/product/${id}`);
      dispatch(slice.actions.getProduct(response.data.product));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeProduct(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/product`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateProduct(values, needUpdate = true) {
  return async (dispatch) => {
    dispatch(slice.actions.startUpdateLoading());
    try {
      const response = await axios.put(`${newApi}/products`, values);
      const newValue = response.data.product;
      dispatch(slice.actions.getProduct({}));
      if (needUpdate) {
        dispatch(slice.actions.updateProductReload(newValue));
      } else {
        dispatch(slice.actions.updateProductReload(values));
      }
      dispatch(slice.actions.stopUpdateLoading());
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteProduct(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/product/${newId}`);
      dispatch(slice.actions.deleteProduct(newId));
      dispatch(slice.actions.stopLoading());
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function duplicateProduct(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/product-duplicate/${newId}`);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
