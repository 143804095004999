import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  fidelityList: [],
  fidelity: null
};

const slice = createSlice({
  name: 'fidelity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getFidelityListSuccess(state, action) {
      state.isLoading = false;
      state.fidelityList = action.payload;
    },

    getFidelitySuccess(state, action) {
      state.isLoading = false;
      state.fidelity = action.payload;
    },

    deleteFidelity(state, action) {
      const deleteFidelity = filter(state.fidelityList, (fidelity) => fidelity._id !== action.payload);
      state.fidelityList = deleteFidelity;
    }
  }
});

// Reducer
export default slice.reducer;

export function getFidelityList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/fidelities`);
      dispatch(slice.actions.getFidelityListSuccess(response.data.fidelitys));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteFidelity(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/fidelities/${newId}`);
      dispatch(slice.actions.deleteFidelity(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getFidelity(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/fidelities/${id}`);
      dispatch(slice.actions.getFidelitySuccess(response.data.fidelity));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeFidelity(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/fidelities`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
