import PropTypes from 'prop-types';
import { TableRow, TableCell, Box } from '@mui/material';
import EmptyContent from '../EmptyContent';

TableNoData.propTypes = {
  isNotFound: PropTypes.bool
};

export default function TableNoData({ isNotFound }) {
  // Se não estiver "sem registros", retorna apenas uma célula vazia
  if (!isNotFound) {
    return (
      <TableRow>
        <TableCell colSpan={9} sx={{ p: 0 }} />
      </TableRow>
    );
  }

  // Se estiver "sem registros", renderiza a animação e centraliza o conteúdo
  return (
    <TableRow>
      <TableCell
        colSpan={11}
        sx={{
          p: 0,
          border: 0,
          height: 300, // Ajuste conforme a altura desejada
          textAlign: 'center',
          verticalAlign: 'middle'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            // Keyframes para animação de fade/slide
            '@keyframes fadeIn': {
              '0%': { opacity: 0 },
              '100%': { opacity: 1 }
            },
            animation: 'fadeIn 0.5s ease-in-out'
          }}
        >
          <EmptyContent
            title="Sem registros"
            sx={{
              // Ajuste opcional da ilustração
              '& span.MuiBox-root': { height: 160 }
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}
