/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useEffect, useContext, Fragment } from 'react';
import { Outlet, Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack5';
import io from 'socket.io-client';
import moment from 'moment';
// utils
import { Icon } from '@iconify/react';
import axios from 'axios';
// material
import { styled, useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import {
  Alert,
  Paper,
  AlertTitle,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
// routes
import Gleap from 'gleap';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useToggle from '../../hooks/useToggle';
import useResponsive from '../../hooks/useResponsive';
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import audio from '../../sounds/notification.mp3';
import audioLogout from '../../sounds/logout.mp3';

import { getRequestList, getAllRequestList, updateRequest, getRequestListNumbers } from '../../redux/slices/request';
import { useDispatch, useSelector } from '../../redux/store';
import { connectInstance } from '../../redux/slices/whatsapp';
import { getReportList } from '../../redux/slices/report';
import { SocketContext } from '../../contexts/SocketContext';

import {
  getBrothers,
  getSound,
  updateRestaurant,
  updateRestaurant2,
  updateRestaurantShopkeeper
} from '../../redux/slices/restaurant';
import { urlWebsocket, api } from '../../config';

import Tawk from '../../components/Tawk';

//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { getContractUser, updateContract } from '../../redux/slices/contract';
import Markdown from '../../components/Markdown';
import { convertPay } from '../../utils/stringsFormat';
import { fCurrency, fMoney } from '../../utils/formatNumber';
import styles from '../../components/_dashboard/request/RequestStyle';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const [imagePopup, setImagePopup] = useState('');
  const [popup, setPopup] = useState(true);
  const isDesktop = useResponsive('up', 'md');
  const [sem, setSem] = useState(false);
  const [popupReport, setPopupReport] = useState(true);
  const { reportListLate } = useSelector((state) => state.report);
  const { brothersList, restaurant } = useSelector((state) => state.restaurant);
  const { restaurantRequests } = useSelector((state) => state.restaurant);
  const [urlPopup, setUrlPopup] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user, logout } = useAuth();
  const [sound] = useState(process.env.NODE_ENV !== 'development' ? new Audio(audio) : new Audio());
  const [soundLogout] = useState(new Audio(audioLogout));
  const socket = useContext(SocketContext);
  const [request, setRequest] = useState();
  const [open2, setOpen2] = useState(false);
  const [idSelected, setIdSelected] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const { toggle: open3, onOpen, onClose } = useToggle();
  const { requestList } = useSelector((state) => state.request);
  const [motive, setMotive] = useState('');
  const [momentLogin, setMomentLogin] = useState(localStorage.getItem('momentLogin'));
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [openPaused, setOpenPaused] = useState(false);
  const [keyBlock, setKeyBlock] = useState('');
  const { updateProfile } = useAuth();

  useEffect(() => {
    const checkTime = async () => {
      const now = new Date();
      if (now.getHours() === 7 && now.getMinutes() === 59) {
        alert('Você foi desconectado!');
        await logout();
        navigate('/');
      }
    };

    const intervalId = setInterval(checkTime, 30000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (user.type > 4) {
      const checkTime = async () => {
        if (momentLogin) {
          const timelimit = 3600 * 8;
          const now = Date.now();
          const diffInSeconds = (now - momentLogin) / 1000;
          const timeRemaining = parseInt(timelimit - diffInSeconds, 10);
          setTimeRemaining(timeRemaining);
          if (timeRemaining <= 300) {
            if (timeRemaining % 15 === 0) soundLogout.play();
          }
          if (diffInSeconds >= timelimit) {
            await logout();
            window.location.reload();
          }
        }
      };

      const intervalId = setInterval(checkTime, 1000);

      return () => clearInterval(intervalId);
    }
  }, [momentLogin]);

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClickOpenNew = (request) => {
    setRequest(request);
    setOpen2(true);
  };

  const handleChangeMotive = (event) => {
    setMotive(event.target.value);
  };

  const handleRemovePause = (event) => {
    setOpenPaused(false);
    dispatch(
      updateRestaurantShopkeeper({
        _id: user.restaurant,
        'paused.status': false,
        'paused.requests': 0,
        'paused.reasons': []
      })
    );
    socket.emit('sendRemoveOccurence', {
      id: user.restaurant._id,
      message: 'Loja liberada'
    });
  };

  const handleCloseDelete = () => {
    if (motive !== '' && motive.length > 4) {
      dispatch(updateRequest({ _id: idSelected, motive, status: 'canceled' }, false, 'all'));
      setOpenDelete(false);
      setIdSelected('');
      setMotive('');
    } else {
      alert('Digite um motivo (mínimo 5 caracteres)');
    }
  };

  const { contractListUser } = useSelector((state) => state.contract);
  const [modals, setModals] = useState([]);

  useEffect(() => {
    if (contractListUser && Array.isArray(contractListUser) && !localStorage.getItem('accessTokenAdm')) {
      const newModals = Array.from({ length: contractListUser.length }, () => ({ open: true, accept: false }));
      setModals(newModals);
    }
  }, [contractListUser]);

  const handleAgreeChange = (index) => (event) => {
    const newModals = [...modals];
    newModals[index].accept = event.target.checked;
    setModals(newModals);
  };

  const handleAcceptContract = (index) => () => {
    const newModals = [...modals];
    newModals[index].open = false;
    setModals(newModals);

    const values = {
      action: 'add',
      _id: contractListUser[index]._id,
      user: user._id
    };
    dispatch(updateContract(values));
  };

  const handleRecuseContract = (index) => () => {
    const newModals = [...modals];
    newModals[index].open = false;
    setModals(newModals);

    const values = {
      action: 'recuse',
      _id: contractListUser[index]._id,
      user: user._id
    };
    dispatch(updateContract(values));
  };

  const handleChangeButton = (event, id) => {
    setOpen2(false);
    if (event === 'canceled') {
      setIdSelected(id);
      setOpenDelete(true);
    } else {
      dispatch(updateRequest({ _id: id, status: event }, false, 'all'));
      setRequest(null);
      setOpen2(true);
    }
  };

  const handleLogOutPause = async () => {
    await logout();
    navigate('/');
  };
  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber;
  };

  const handleOpenModal = (index) => () => {
    const newModals = [...modals];
    newModals[index].open = true;
    setModals(newModals);
  };

  const handlePdf = (request) => {
    setRequest(request);
    onOpen();
  };
  useEffect(() => {
    dispatch(getReportList('not_pay'));
    if (user.type === 3 || user.type === 4) {
      dispatch(getContractUser(user._id));
    }
  }, [dispatch, user.restaurant]);

  const handlePausedInfo = () => {
    enqueueSnackbar('Loja fechada por excesso de pedidos cancelados', { variant: 'error' });
    setOpenPaused(true);
  };

  useEffect(async () => {
    if (user.restaurant) {
      await dispatch(getBrothers(user.restaurant._id));
      if (user.restaurant?.paused?.status === true) {
        handlePausedInfo();
      }
      setSem(true);
    }

    const gleapKey =
      user.type === 3 || user.type === 4 ? 'jWmgG5ff4dljnCUYguogYNuUvIlJBqq1' : 'z3wnETZ8q45f8u034ZKWVmGgagi5kg07';
    if (user.fields?.includes('chat gleap') || user.type === 5 || user.type === 6) {
      Gleap.initialize(gleapKey);
      Gleap.identify(user._id, {
        name: user.name,
        email: user.email,
        tel: user.tel,
        createdAt: new Date(),
        customData: {
          city: '',
          type: user.type === 3 || user.type === 4 ? 'Lojista' : 'Licenciado'
        }
      });
      Gleap.showFeedbackButton(false);

      Gleap.setReplayOptions({
        sampling: {
          mouseInteraction: {
            MouseUp: false,
            MouseDown: false,
            Click: false,
            ContextMenu: false,
            DblClick: false,
            Focus: false,
            Blur: false,
            TouchStart: false,
            TouchEnd: false
          }
        }
      });
    }
  }, [dispatch]);

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (user.type > 2) {
      const interval = setInterval(async () => {
        await dispatch(getSound());

        if (restaurantRequests > 0 && localStorage.getItem('soundNotification') !== '0' && !isPlaying) {
          setIsPlaying(true);
          try {
            const savedVolume = localStorage.getItem('appVolume');
            sound.volume = savedVolume !== null ? parseInt(savedVolume, 10) / 100 : 0.5;
            sound.muted = false;
            sound.play();
            setTimeout(() => {
              sound.volume = 0;
              sound.muted = true;
              setIsPlaying(false);
            }, 12000);
          } catch {
            setIsPlaying(false);
          }
        }
      }, 40000);

      return () => clearInterval(interval);
    }
  }, [dispatch, restaurantRequests, isPlaying, user.type]);

  useEffect(async () => {
    if (Notification.permission !== 'granted') {
      const permission = await Notification.requestPermission();
    }

    if (sem) {
      if (brothersList.length > 0) {
        brothersList.forEach((elem) => {
          socket?.emit('newUser', { _id: elem._id });
        });
      } else if (user.restaurant) {
        socket?.emit('newUser', user.restaurant);
      }

      socket?.on('getNotificationnDeliveryMan', (e) => {
        enqueueSnackbar(e.message, { variant: 'success' });
      });

      socket?.on('getMessage', (e) => {
        enqueueSnackbar(`Nova mensagem de ${e.user}`, { variant: 'success' });
      });

      socket?.on('getOccurence', async (e) => {
        await updateProfile();
        handlePausedInfo();
      });

      socket?.on('removeOccurence', async (e) => {
        await updateProfile();
        setOpenPaused(false);
      });

      socket?.on('getNotification', (e) => {
        if (localStorage.getItem('soundNotification') !== '0') {
          const savedVolume = localStorage.getItem('appVolume');
          sound.volume = savedVolume !== null ? parseInt(savedVolume, 10) / 100 : 0.5;
          sound.muted = false;
          sound.play();
          setTimeout(() => {
            sound.volume = 0;
            sound.muted = true;
          }, 12000);
        }
        enqueueSnackbar('Novo Pedido', { variant: 'success' });
        dispatch(getRequestListNumbers(1, 20, '', 'all', 'today'));
        dispatch(getRequestList(1, 20, '', 'all', user.type <= 2 ? 'today' : 'yesterday-today'));
        if (Notification.permission === 'granted') {
          const notification = new Notification('Você recebeu um novo pedido.', {
            body: 'Clique para abrir o painel.'
          });
        }
      });

      socket?.on('getNotificationConnect', () => {
        enqueueSnackbar('WhatsApp conectado', { variant: 'success' });
        dispatch(connectInstance(1, user._id));
      });

      socket?.on('getNotificationDisconnect', (e) => {
        enqueueSnackbar(e.message, { variant: 'error' });
        dispatch(connectInstance(1, user._id));
      });

      socket?.on('getRequests', () => {
        // if (brothersList.length > 0) {
        //   dispatch(getAllRequestList(1, 20, ''));
        // }
        // dispatch(getRequestList(1, 20, '', 'all', 'today'));
      });
    } else {
      if (user.type === 1 || user.type === 2) socket?.emit('newUser', { _id: 'adm' });
      if (user.type === 3 || user.type === 4) {
        user.restaurants?.forEach((element) => {
          socket?.emit('newUser', { _id: element });
        });
      }
      socket?.on('getNotification', (e) => {
        if (localStorage.getItem('soundNotification') !== '0') {
          const savedVolume = localStorage.getItem('appVolume');
          sound.volume = savedVolume !== null ? parseInt(savedVolume, 10) / 100 : 0.5;
          sound.play();
        }
        enqueueSnackbar('Novo Pedido', { variant: 'success' });
        dispatch(getRequestList(1, 30, '', 'all', user.type <= 2 ? 'today' : 'yesterday-today'));
        const notification = new Notification('Você recebeu um novo pedido.', {
          body: 'Clique para abrir o painel.'
        });

        // if (brothersList.length > 0) {
        //   dispatch(getAllRequestList(1, 20, ''));
        // }
      });
    }
  }, [sem, socket]);

  useEffect(() => {
    if (user) {
      if (localStorage.getItem('accessTokenAdm') && user.type <= 3) return;
      socket?.emit('online', { _id: user._id, disp: 'web' });
    }
  }, [socket, user]);

  const CountdownTimer = ({ createdAt }) => {
    const [countdown, setCountdown] = useState(0);

    useEffect(() => {
      const currentTime = moment();
      const diffInSeconds = currentTime.diff(moment(createdAt), 'seconds');
      const remainingTime = Math.max(0, 620 - diffInSeconds);

      setCountdown(remainingTime);

      const interval = setInterval(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
        }
      }, 1000);

      return () => clearInterval(interval);
    }, [countdown, createdAt]);

    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;

    return (
      <>
        <span
          style={{
            backgroundColor: '#ff9898',
            color: 'black',
            fontSize: '20px',
            padding: '5px 10px',
            borderRadius: '5px',
            marginTop: '15px'
          }}
        >
          <Icon icon="solar:timer" /> Você tem {minutes}:{seconds < 10 ? '0' : ''}
          {seconds} para aceitar
        </span>
      </>
    );
  };

  return (
    <RootStyle>
      {user.type <= 4 && (
        <Helmet>
          <script>
            {`window.$sleek=[];window.SLEEK_PRODUCT_ID=545953491;(function(){d=document;s=d.createElement("script");s.src="https ://client.sleekplan.com/sdk/e.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}
          </script>
        </Helmet>
      )}
      {reportListLate.length > 0 && user.type > 2 ? (
        <div style={!popupReport ? { display: 'none' } : {}} className="popup black">
          <div className="closePopup" onClick={() => setPopupReport(false)}>
            x
          </div>
          <Container sx={{ mb: 3 }}>
            <Alert severity="error">
              <AlertTitle style={{ textAlign: 'left' }}>Faturas atrasadas!</AlertTitle>
              Você possui faturas atrasadas. Clique{' '}
              <Link
                underline="none"
                component={RouterLink}
                to={PATH_DASHBOARD.report.list}
                style={{ color: 'red', fontWeight: 'bold' }}
              >
                aqui
              </Link>{' '}
              para visualizar.
            </Alert>
          </Container>
        </div>
      ) : (
        ''
      )}
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(isDesktop &&
            collapseClick && {
            marginLeft: '102px'
          })
        }}
      >
        {restaurant?.test && (
          <Container sx={{ mb: 3 }}>
            <Alert severity="warning">
              <AlertTitle>Estabelecimento em ambiente de teste</AlertTitle>
              Este estabelecimento está em ambiente de teste e seus pedidos serão excluídos automaticamente depois de um
              período.
            </Alert>
          </Container>
        )}
        {reportListLate.length > 0 && user.type > 2 ? (
          <Container sx={{ mb: 3 }}>
            <Alert severity="error">
              <AlertTitle>Faturas atrasadas!</AlertTitle>
              Você possui faturas atrasadas. Clique{' '}
              <Link
                underline="none"
                component={RouterLink}
                to={PATH_DASHBOARD.report.list}
                style={{ color: 'red', fontWeight: 'bold' }}
              >
                aqui
              </Link>{' '}
              para visualizar.
            </Alert>
          </Container>
        ) : (
          ''
        )}

        {contractListUser.map((contract, index) => (
          <div key={index}>
            <Dialog
              maxWidth="md"
              fullWidth
              open={modals[index]?.open}
              onClose={() => handleAgreeChange(index)({ target: { checked: false } })}
              PaperProps={{
                style: {
                  width: '80vw',
                  maxWidth: '900px',
                  height: 'auto',
                  maxHeight: '90vh',
                  padding: '20px'
                }
              }}
            >
              <DialogTitle style={{ fontSize: 25, backgroundColor: '#fff' }}>{contract.title}</DialogTitle>
              <DialogContent style={{ width: '100%', padding: '20px', overflowY: 'auto' }}>
                <Markdown style={{ padding: '20px 20px' }} children={contract.text} />
              </DialogContent>
              <DialogActions style={{ backgroundColor: '#f1f1f1', width: '100%' }}>
                <FormControlLabel
                  control={<Checkbox checked={modals[index]?.accept} onChange={handleAgreeChange(index)} />}
                  label={
                    <Typography variant="body1" style={{ fontSize: '17px' }}>
                      Li e concordo com os termos
                    </Typography>
                  }
                />
                {!contract.required && (
                  <Button disabled={!modals[index]?.accept} onClick={handleRecuseContract(index)} variant="contained">
                    Não aceito
                  </Button>
                )}
                <Button disabled={!modals[index]?.accept} onClick={handleAcceptContract(index)} variant="contained">
                  Ciente
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ))}

        {user.type === 3 && user.debt === true && (
          <div>
            <Dialog open="true">
              <DialogContent style={{ width: '450px', height: '320px' }}>
                <div style={{ textAlign: 'center' }}>
                  <img
                    src="/static/logo/Icon_alert_pagamento.png"
                    style={{ width: 100, display: 'block', margin: '0 auto', marginBottom: 15, marginTop: 20 }}
                    alt=""
                  />
                  <h2 style={{ fontSize: 24 }}>Conta bloqueada! Não identificamos o pagamento em aberto.</h2>
                  <p style={{ fontSize: 14, color: 'gray', marginTop: 10 }}>
                    É fácil resolver, sabia? Entra em contato com a gente pra acertar isso juntos. Valeu!
                  </p>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    window.Tawk_API?.maximize();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    borderRadius: '4px',
                    transition: 'all 200ms ease-in-out 0s',
                    cursor: 'pointer',
                    padding: '0.5rem 1rem',
                    color: 'rgb(21, 61, 59)',
                    border: '1px solid rgb(21, 61, 59)',
                    width: '100%',
                    textTransform: 'none'
                  }}
                >
                  Chamar no Chat
                </Button>
                <Button
                  onClick={() => window.open('https://api.whatsapp.com/send?phone=5569984050653', '_blank')}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    borderRadius: '4px',
                    transition: 'all 200ms ease-in-out 0s',
                    cursor: 'pointer',
                    padding: '0.5rem 1rem',
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgb(21, 61, 59)',
                    width: '100%',
                    textTransform: 'none'
                  }}
                >
                  Chamar no WhatsApp
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}

        <Outlet />
      </MainStyle>

      <Dialog maxWidth="xs" open={timeRemaining < 300 && timeRemaining}>
        <DialogContent>
          {timeRemaining > 0 && (
            <div style={{ textAlign: 'center' }}>
              <h3>
                Sua sessão expira em {Math.floor(timeRemaining / 60)}:{timeRemaining % 60 < 10 ? '0' : ''}
                {timeRemaining % 60}
              </h3>
              <p>Clique no botão abaixo para continuar seu acesso.</p>
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ paddingTop: '0px', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={() => {
              setMomentLogin(Date.now());
              localStorage.setItem('momentLogin', Date.now());
              onClose();
            }}
          >
            CONTINUAR ACESSO
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="xs"
        open={openPaused}
        PaperProps={{ style: { borderRadius: 'none', width: '40%', maxWidth: '1000px' } }}
      >
        <DialogTitle>Loja Penalizada por Excesso de Pedidos Cancelados</DialogTitle>
        <DialogContent>
          <br />
          <p>
            Sua loja foi temporariamente fechada devido ao alto número de pedidos cancelados. Assim que a situação for
            regularizada, você poderá retomar suas atividades normalmente.
          </p>
          <br />
          <div style={{ border: '1px solid #e7e7e7', padding: '10px 5px', borderRadius: 10 }}>
            <b>Motivos de cancelamento:</b>
            {user.restaurant?.paused?.reasons?.map((row) => (
              <p>- {row}</p>
            ))}
          </div>
          <br />
          <Alert severity="error">
            Lembre-se: Evite o cancelamento de pedidos para manter sua nota e evitar problemas futuros.
          </Alert>
          <br />
          <p>
            Digite a palavra <b>confirmar</b> abaixo para continuar.
          </p>
          <TextField
            autoFocus
            margin="dense"
            id="motive"
            label="Palavra-chave"
            type="text"
            inputProps={{ minLength: 5 }}
            fullWidth
            value={keyBlock}
            onChange={(e) => setKeyBlock(e.target.value)}
          />
        </DialogContent>
        <DialogActions style={{ paddingTop: '0px', justifyContent: 'center', paddingBottom: '20px' }}>
          <Button
            variant="contained"
            onClick={handleLogOutPause}
            style={{
              backgroundColor: '#FF4842',
              color: '#fff',
              padding: '10px 20px',
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '1rem',
              borderRadius: '8px',
              boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)'
            }}
          >
            Sair
          </Button>
          <Button
            variant="contained"
            onClick={handleRemovePause}
            style={{
              backgroundColor: keyBlock.toLowerCase() === 'confirmar' ? 'green' : 'gray',
              color: '#fff',
              padding: '10px 20px',
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '1rem',
              borderRadius: '8px',
              boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)'
            }}
            disabled={keyBlock.toLowerCase() !== 'confirmar'}
          >
            Liberar meu acesso
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth="xs" open={openDelete}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="motive"
            label="Motivo da Exclusão"
            type="motive"
            inputProps={{ minLength: 5 }}
            fullWidth
            onChange={handleChangeMotive}
            value={motive}
          />
        </DialogContent>
        <DialogActions style={{ paddingTop: '0px' }}>
          <Button onClick={handleCloseDelete}>Salvar</Button>
        </DialogActions>
      </Dialog>

      {!request ? (
        <></>
      ) : (
        <>
          <Dialog
            maxWidth="xl"
            open={open2}
            PaperProps={{ style: { borderRadius: 'none', width: '80%', maxWidth: '1000px' } }}
          >
            <DialogTitle>
              <div style={{ marginBottom: '25px' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h6">
                      <span>
                        <Icon icon="mdi:user" />{' '}
                        {request.customer ? (
                          <>
                            {request.customer.name}{' '}
                            <span
                              style={{
                                backgroundColor: 'gray',
                                borderRadius: '2px',
                                padding: 3,
                                color: 'white'
                              }}
                            >
                              {request.customer.requests}º
                            </span>{' '}
                          </>
                        ) : (
                          `${request.customerCustom.name}`
                        )}{' '}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h6">
                      <b>
                        {' '}
                        ID pedido: <span style={{ textTransform: 'uppercase' }}>#{`${request._id.slice(-7)}`}</span>
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="h6">
                      <Icon icon="mdi:calendar" /> {moment(request.createdAt).format('HH:mm \n DD/MM/YY')}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
              <div style={{ textAlign: 'center' }}>
                {request.status === 'not_pay' ? (
                  <>
                    <CountdownTimer createdAt={request.createdAt} />
                    <br />
                    <br />
                  </>
                ) : (
                  ''
                )}
              </div>

              <Grid>
                <div style={{ border: '0.5px solid #d3d3d3', borderRadius: '5px', padding: '30px', flex: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <div style={{ fontSize: 20, marginTop: 17 }}>
                        {request.typeDelivery === 1 ? (
                          <>
                            <p>
                              Endereço: <b>{request.local.address}</b>
                            </p>
                            <p>
                              Número: <b>{request.local.num || ''}</b>
                            </p>
                            <p>
                              Complemento: <b>{request.local.complement}</b>
                            </p>
                            <p>
                              CEP: <b>{request.local.cep}</b>
                            </p>
                            <p>
                              Bairro: <b>{request.local.district}</b>
                            </p>
                            <p>
                              Cidade:{' '}
                              <b>
                                {request.local.city} - {request.local.uf}
                              </b>
                            </p>
                          </>
                        ) : (
                          <>
                            <Icon icon="solar:bag-bold" /> Retirar no local
                          </>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div style={{ fontSize: 20, marginTop: 17 }}>
                        <span>
                          <Icon icon="mdi:phone" />{' '}
                          {request.customer ? (
                            <>{formatPhoneNumber(request.customer.tel)}</>
                          ) : (
                            `${formatPhoneNumber(request.customerCustom.tel)}`
                          )}
                        </span>
                        <span>
                          <br />
                          <Icon icon="solar:card-2-bold" /> {convertPay(request.typePay)}
                          {request.prices?.payback > 0 && (
                            <>
                              <div>
                                <br />
                                Troco para: <b>R${fMoney(request.prices?.payback || 0)}</b>
                              </div>
                              <div>
                                Troco: <b>R${fMoney((request.prices?.payback || 0) - request.prices.final)}</b>
                              </div>
                            </>
                          )}
                          <br />
                          {request.typeDelivery === 1 ? <>Taxa de entrega R${fMoney(request.prices.delivery)}</> : ''}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div style={{ fontSize: 20, marginTop: 17 }}>
                        <span>
                          <Icon icon="mdi:wallet" /> Carteira: R${fMoney(request.prices?.wallet || 0)}
                          <br />
                          <Icon icon="mdi:tag" /> Cupom: {request.coupon?.name || 'Nenhum'}
                          <br />
                          <Icon icon="mdi:discount" /> Desconto: R${fMoney(request.prices.discount)}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ display: 'none' }}>
                      <div
                        style={{
                          fontSize: 20,
                          marginTop: 17,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}
                      >
                        <span>
                          <Icon icon="mdi:calendar" /> {moment(request.createdAt).format('HH:mm \n DD/MM/YY')}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <br />
                <Grid item xs={18}>
                  <div style={{ borderRadius: '5px', overflowX: 'auto' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '16px' }}>
                      {request?.products.map((row) => (
                        <div
                          key={row.product.name}
                          style={{ border: '1px solid #ccc', padding: '16px', borderRadius: '8px' }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            <div style={{ marginLeft: '8px' }}>
                              <div>
                                {row.qnt}x - {row.product.name}
                              </div>
                              {row.product.description && (
                                <div style={{ maxWidth: '350px', wordWrap: 'break-word' }}>
                                  <small>{row.product.description}</small>
                                </div>
                              )}
                            </div>
                          </div>
                          {row.adds.map((add, index) => (
                            <div key={index} style={{ marginLeft: '16px', fontStyle: 'italic', marginBottom: '4px' }}>
                              {add.desc}
                              {add.itens.map((item, subIndex) => (
                                <div key={subIndex} style={{ marginLeft: '16px' }}>
                                  {item.qnt}x - {item.desc}
                                </div>
                              ))}
                            </div>
                          ))}
                          {row.obs && (
                            <div style={{ marginLeft: '16px', marginTop: '8px' }}>
                              OBS: <b>{row.obs}</b>
                            </div>
                          )}
                          <div style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '8px', textAlign: 'right' }}>
                            R$ {fMoney(row.price ? row.price : row.product.prices.app)}
                          </div>
                        </div>
                      ))}
                    </div>

                    {request.obs && (
                      <p>
                        <div style={styles.space} />
                        OBS: <b>{request.obs}</b>
                      </p>
                    )}
                    <br />
                  </div>

                  <br />

                  <br />

                  {request.deliveryMan ? (
                    <div style={{ background: '#dbdbdb', borderRadius: '6px', padding: '12px', color: '#363636' }}>
                      <div>
                        <h3>Entregador:</h3>
                        <div>
                          <span>Nome: {request.deliveryMan?.name}</span>
                          <br />
                          <span>Telefone: {request.deliveryMan?.tel}</span>{' '}
                        </div>
                        {request.status === 'callDelivery' && !request.deliveryMan ? (
                          <h5>Solicitando entregador</h5>
                        ) : (
                          ''
                        )}
                        {request.status === 'callDelivery' && request.deliveryMan ? (
                          <h5>{request.deliveryMan?.name} está indo retirar </h5>
                        ) : (
                          ''
                        )}
                        {request.status === 'quit_sender' && request.deliveryMan ? (
                          <h5>{request.deliveryMan?.name} indo até o cliente </h5>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {request.status === 'canceled' ? (
                    <>
                      <br />
                      Motivo exclusão: <b>{request.motive}</b>
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <br />
              <div>
                <Grid style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
                  <span
                    style={{
                      gridColumn: '1 / span 1',
                      backgroundColor: '#e7e7e7',
                      fontSize: '20px',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      width: '220px',
                      marginRight: '100px' // Para garantir que esteja alinhado à esquerda
                    }}
                  >
                    <b>Receber:</b> R${fMoney(request.prices.final)}
                  </span>
                  {request.status !== 'success' && request.status !== 'canceled' ? (
                    <Button
                      fullWidth
                      size="large"
                      style={{ gridColumn: '2 / span 1', marginLeft: '-10px', borderWidth: '3px' }}
                      onClick={() => handlePdf(request)}
                      color="inherit"
                      variant="outlined"
                    >
                      Imprimir
                    </Button>
                  ) : (
                    ''
                  )}

                  {request.status !== 'success' && request.status !== 'canceled' && (
                    <Button
                      size="large"
                      style={{ gridColumn: '3 / span 1', marginRight: 10, borderWidth: '3px' }}
                      onClick={() => handleChangeButton('canceled', request._id)}
                      color="error"
                      variant="outlined"
                    >
                      Recusar
                    </Button>
                  )}

                  {request.status === 'not_pay' && (
                    <Button
                      fullWidth
                      size="large"
                      style={{ gridColumn: '4 / span 1', marginRight: 10, borderWidth: '3px' }}
                      color="warning"
                      onClick={() => handleChangeButton('preparing', request._id)}
                      variant="contained"
                    >
                      Aceitar pedido
                    </Button>
                  )}

                  {request.status === 'preparing' && (
                    <>
                      {request.typeDelivery === 1 ? (
                        <Button
                          fullWidth
                          size="large"
                          style={{ gridColumn: '4 / span 1', marginRight: 10, borderWidth: '3px' }}
                          color="warning"
                          onClick={() => handleChangeButton('quit_sender', request._id)}
                          variant="contained"
                        >
                          Ir a entrega
                        </Button>
                      ) : null}

                      {request.typeDelivery === 2 ? (
                        <Button
                          fullWidth
                          size="large"
                          style={{ gridColumn: '4 / span 1', marginRight: 10, borderWidth: '3px' }}
                          color="warning"
                          onClick={() => handleChangeButton('ready', request._id)}
                          variant="contained"
                        >
                          Pronto para buscar
                        </Button>
                      ) : null}

                      {request.typeDelivery === 1 && request.restaurant?.deliveryMan === true && (
                        <Button
                          fullWidth
                          size="large"
                          style={{ gridColumn: '5 / span 1', marginLeft: 10, borderWidth: '3px' }}
                          color="warning"
                          onClick={() => handleChangeButton('callDelivery', request._id)}
                          variant="contained"
                        >
                          Solicitar entregador
                        </Button>
                      )}
                    </>
                  )}

                  {(request.status === 'quit_sender' || request.status === 'ready') && (
                    <Button
                      style={{ gridColumn: '4 / span 1', marginRight: 10 }}
                      color="success"
                      onClick={() => handleChangeButton('success', request._id)}
                      variant="contained"
                    >
                      Entregue
                    </Button>
                  )}
                </Grid>
              </div>
            </DialogActions>
          </Dialog>
        </>
      )}
    </RootStyle>
  );
}
