// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
import { api } from '../config';
// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  return (
    <MAvatar
      src={user.restaurant ? `${api}/files/restaurants/logos/${user.restaurant.fileName}` : ''}
      alt={user.displayName}
      color='primary'
      {...other}
    >
      {createAvatar(user.name).name}
    </MAvatar>
  );
}
