import PropTypes from 'prop-types';


import { Icon } from '@iconify/react';
import { paramCase } from 'change-case';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import listOutline from '@iconify/icons-eva/list-outline';
import moment from 'moment';

// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle
} from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';


// ----------------------------------------------------------------------

UserMoreMenu.propTypes = {
  onDelete: PropTypes.func,
  id: PropTypes.string,
  link: PropTypes.string,
  text: PropTypes.string,
  parent: PropTypes.string,
  categoryListV2: PropTypes.object
};

export default function UserMoreMenu({ onDelete, id, link = PATH_DASHBOARD.licensed.root, text = '', deliveryMan, contractInfo, parent, categoryListV2 }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const location = useLocation();


  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleOpenInfo = () => {
    setOpenInfo(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const deleteItem = () => {
    onDelete();
    setOpenDelete(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {
          location.pathname === "/dashboard/delivery-man/list" ? (<MenuItem onClick={handleOpenInfo} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={listOutline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Informações" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>) : ("")
        }
        {
          location.pathname === "/dashboard/campaigns/list" ? (<MenuItem component={RouterLink} to={`${link}/${paramCase(id)}/edit?view=1`} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={listOutline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Ver detalhes" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>) : ("")
        }
        <MenuItem component={RouterLink} to={`${link}/${paramCase(id)}/edit`} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        
        {link === '/dashboard/user' && parent ? (<MenuItem onClick={handleOpenDelete} sx={{ color: 'red' }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Excluir" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>) : (<></>)
        }
        {link !== '/dashboard/user' ? (<MenuItem onClick={handleOpenDelete} sx={{ color: 'red' }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Excluir" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>) : (<></>)
        }

      </Menu>


      {
        location.pathname === "/dashboard/delivery-man/list" ? (<Dialog maxWidth="xs" open={openInfo} onClose={handleCloseInfo}>
          <DialogContent>
            <div>
              <b>Entregador</b>: {deliveryMan?.name}
              <br />
              <b>E-mail</b>: {deliveryMan?.email}
              <br />
              <b>Status de entregador</b>: {(deliveryMan?.online === true && 'Online') ||
                (deliveryMan?.online === false && 'Offline')}
            </div>
            <br />
            Corridas aceitas ({deliveryMan?.requestSuccess.length})
            <div>
              {deliveryMan?.requestSuccess.map((item, index) => (
                <b><p key={index}>JAO-{item.slice(-7)}</p></b>
              ))}
            </div>
            <br />
            Corridas recusadas ({deliveryMan?.requestRecuse.length})
            <div>
              {deliveryMan?.requestRecuse.map((item, index) => (
                <b><p key={index}>JAO-{item.slice(-7)}</p></b>
              ))}
            </div>
            <br />
          </DialogContent>
          <DialogActions style={{ paddingTop: '0px' }}>
            <Button onClick={handleCloseInfo}>Fechar</Button>
          </DialogActions>
        </Dialog>) : ('')
      }



      <Dialog maxWidth="xs" open={openDelete} onClose={handleCloseDelete}>
        <DialogContent>
          Você tem certeza que deseja excluir?
          {text ? (
            <>
              <br />
              <br />
              {text}
            </>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions style={{ paddingTop: '0px' }}>
          <Button onClick={handleCloseDelete}>Cancelar</Button>
          <Button style={{ color: 'red' }} onClick={deleteItem}>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>



    </>
  );
}
