import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack5';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import jsonToFormData from '@ajoelp/json-to-formdata';
// material
import { LoadingButton } from '@material-ui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Select,
  Autocomplete,
  Alert,
  FormControl,
  InputLabel,
  Typography,
  FormHelperText
} from '@material-ui/core';
import { useSelector } from 'react-redux';

// utils
import useAuth from '../../../hooks/useAuth'
import { fData } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import { UploadAvatar } from '../../upload';
// redux
import { useDispatch } from '../../../redux/store';
import { storeDeliveryManUnauth, updateDeliveryMan } from '../../../redux/slices/deliveryMan';
import { api } from '../../../config';
import { getMunicipios } from '../../../redux/slices/licensed';

// ----------------------------------------------------------------------

export default function DeliveryManNewFormUnauth() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { municipiosList } = useSelector((state) => state.licensed);
  const [cidades, setCidades] = useState([]);
  const dispatch = useDispatch();
  const fieldsValidation = {
    name: Yup.string().required('Nome é obrigatório'),
    cpf: Yup.string().required('CPF é obrigatório'),
    email: Yup.string().email().required('E-mail é obrigatório'),
    tel: Yup.string().required('Celular é obrigatório'),
    cnh: Yup.string().required('CNH é obrigatória'),
    licensePlate: Yup.string().required('Placa é obrigatório')
  };

  useEffect(() => {
    dispatch(getMunicipios());
  }, [dispatch]);


  const NewDeliveryManSchema = Yup.object().shape(fieldsValidation);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      cpf: '',
      tel: '',
      email: '',
      cnh: '',
      obs: '',
      licensePlate: '',
      local: [],
    },
    validationSchema: NewDeliveryManSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        // eslint-disable-next-line
        const cidad = cidades.find(c => c.codigo_ibge == values.city) 
        values.local = [{ id: cidad.codigo_ibge, label: cidad.nome_municipio }]
        const result = await dispatch(storeDeliveryManUnauth(values));
        if (result.error) {
          setSubmitting(false);
          setErrors({ afterSubmit: result.message });
        } else {
          resetForm();
          setSubmitting(false);
          enqueueSnackbar(result.message, { variant: 'success' });
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const changeUf = (event) => {
    event.preventDefault();
    const result = municipiosList.find((estado) => estado.sigla_uf === event.target.value);
    formik.setFieldValue('uf', event.target.value);
    formik.setFieldValue('city', result.cidades[0].codigo_ibge);
    setCidades(result.cidades);
  };

  const { errors, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid sx={{justifyContent: "center"}} container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Nome"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    type="email"
                    label="E-mail"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Celular"
                    {...getFieldProps('tel')}
                    error={Boolean(touched.tel && errors.tel)}
                    helperText={touched.tel && errors.tel}
                  />
                  <TextField
                    fullWidth
                    label="Placa"
                    {...getFieldProps('licensePlate')}
                    error={Boolean(touched.licensePlate && errors.licensePlate)}
                    helperText={touched.licensePlate && errors.licensePlate}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="CPF"
                    {...getFieldProps('cpf')}
                    error={Boolean(touched.cpf && errors.cpf)}
                    helperText={touched.cpf && errors.cpf}
                  />
                  <TextField
                    fullWidth
                    label="CNH"
                    {...getFieldProps('cnh')}
                    error={Boolean(touched.cnh && errors.cnh)}
                    helperText={touched.cnh && errors.cnh}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel>Estado</InputLabel>
                    <Select
                      native
                      label="Estados"
                      {...getFieldProps('uf')}
                      onChange={(e) => changeUf(e)}
                    >
                      <option value="">Selecione um estado</option>
                      {municipiosList
                        ? municipiosList.map((loc) => (
                          <option key={loc.sigla_uf} value={loc.sigla_uf}>
                            {loc.nome_uf}
                          </option>
                        ))
                        : 'Carregando...'}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel>Cidade</InputLabel>
                    <Select native label="Cidade" {...getFieldProps('city')} id="city">
                      {cidades
                        ? cidades.map((loc) => (
                          <option key={loc.codigo_ibge} value={loc.codigo_ibge}>
                            {loc.nome_municipio}
                          </option>
                        ))
                        : 'Carregando...'}
                    </Select>
                  </FormControl>
                </Stack>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Enviar solicitação
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
