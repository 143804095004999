import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack5';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@material-ui/lab';
import jsonToFormData from '@ajoelp/json-to-formdata';
import InputMask from 'react-input-mask';

import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByLatLng,
  geocodeByAddress
} from 'react-google-places-autocomplete';

import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  InputAdornment
} from '@material-ui/core';
import useResponsive from '../../../hooks/useResponsive';
// utils
import { useDispatch } from '../../../redux/store';

import { storeRestaurantUnauth, updateRestaurant } from '../../../redux/slices/restaurant';
// ----------------------------------------------------------------------

RestaurantNewForm.propTypes = {
  currentRestaurant: PropTypes.object,
  municipiosList: PropTypes.array,
  uf: PropTypes.string,
  categoryList: PropTypes.array
};
function MaskedTextField(props) {
  const { mask, ...inputProps } = props;

  return (
    <InputMask mask={mask} {...inputProps}>
      {(inputProps) => <TextField {...inputProps} />}
    </InputMask>
  );
}



export default function RestaurantNewForm({
  municipiosList,
  categoryList
}) {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [cidades, setCidades] = useState([]);
  const isDesktop = useResponsive('up', 'md');

  useEffect(() => {
    if (!selectedAddress) return;
    geocodeByPlaceId(selectedAddress.value.place_id)
      .then((results) => {
        updateAddress(results[0].geometry.location.lat(), results[0].geometry.location.lng());
        formik.setFieldValue('address', results[0].formatted_address);
        formik.setFieldValue(
          'district',
          results[0].address_components.find((e) => e.types.includes('sublocality'))?.long_name
        );
        formik.setFieldValue(
          'num',
          results[0].address_components.find((e) => e.types.includes('street_number'))?.long_name
        );
        console.log(results[0]);
      })
      .catch((error) => console.error(error));
  }, [selectedAddress]);

  const updateAddress = (lat, lng) => {
    geocodeByLatLng({ lat, lng })
      .then((results) => {
        formik.setFieldValue('lat', lat);
        formik.setFieldValue('long', lng);
        setOpen(false);
      })
      .catch((error) => console.error(error));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };



  const changeUf = (event) => {
    event.preventDefault();
    const result = municipiosList.find((estado) => estado.sigla_uf === event.target.value);
    formik.setFieldValue('uf', event.target.value);
    // formik.setFieldValue('city', result.cidades[0].codigo_ibge);
    setCidades(result.cidades);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (categoryList) {
      const type = 1;
      setCategories(categoryList.filter((cat) => cat.parent === 2));
    }
    // eslint-disable-next-line
  }, [categoryList]);

  let validation = {};


  validation = {
    name: Yup.string().required('Nome é obrigatório'),
    address: Yup.string().required('Endereço é obrigatório'),
    // category: Yup.string().oneOf(['0', 0, false], 'Escolha uma categoria!').required(),
    nameResp: Yup.string().required('Nome do responsável é obrigatório'),
    emailResp: Yup.string().required('E-mail do responsável é obrigatório').email('E-mail inválido'),
    commission: Yup.number().required('Comissão é obrigatório'),
  };

  const NewRestaurantSchema = Yup.object().shape(validation);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      needActivate: true,
      city: '0',
      uf: '0',
      category: '',
      type: 2,
      tel1: '',
      tel2: '',
      cnpj: '',
      fuse: 'America/Sao_Paulo',
      address: '',
      num: '',
      slug: '',
      deliveryMan: false,
      district: '',
      logo: null,
      banner: null,
      emailResp: '',
      nameResp: '',
      commission: 10,
      lat: '',
      long: '',
      statusCommerce: 0,
      version: 2,
      date: ''
    },
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        values.local = { uf: values.uf, city: values.city };
        values.coord = { lat: values.lat, long: values.long };
        values.subEcommerce = { status: values.statusCommerce, date: values.date };
        values.tel = [];
        values.tel[0] = values.tel1;
        values.tel[1] = values.tel2;
        values.single = true;
        values.user = {
          name: values.nameResp,
          email: values.emailResp
        };
        const formData = jsonToFormData(values);
        const result = await dispatch(storeRestaurantUnauth(values));
        // await dispatch(storeShopkeeper(user));

        if (result.error) {
          setSubmitting(false);
          setErrors({ afterSubmit: result.message });
        } else {
          resetForm();
          setSubmitting(false);
          enqueueSnackbar(result.message, { variant: 'success' });
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;
  const changeParent = (event) => {
    event.preventDefault();
    formik.setFieldValue('type', event.target.value);
    formik.setFieldValue('category', false);
    // eslint-disable-next-line eqeqeq
    const result = categoryList.filter((cat) => cat.parent == event.target.value);
    setCategories(result);
  };
  const handleInputChange = (e) => {
    const { value } = e.target;
    let maskedValue = value.replace(/\D/g, '');
    if (maskedValue.length > 11) {
      maskedValue = maskedValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5');
    } else {
      maskedValue = maskedValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
    }
    formik.setFieldValue('document', maskedValue);
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3, borderRadius: '0px' }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Nome"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    label="CPF ou CNPJ"
                    mask="999.999.999-99"
                    value={formik.values.document}
                    onChange={handleInputChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.document && Boolean(formik.errors.document)}
                    helperText={formik.touched.document && formik.errors.document}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel>Categoria</InputLabel>
                    <Select native label="Categoria" {...getFieldProps('category')}>
                      <option key={0} value={false}>
                        Selecione uma categoria
                      </option>
                      {categories
                        ? categories.map((cat) => (
                          <option key={cat._id} value={cat._id}>
                            {cat.name}
                          </option>
                        ))
                        : 'Carregando...'}
                    </Select>
                  </FormControl>
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='uf' shrink>Estados</InputLabel>
                    <Select
                      native
                      id='uf'
                      {...getFieldProps('uf')}
                      onChange={(e) => changeUf(e)}
                      label="Estados"
                    >
                      <option value="0">Selecione um estado</option>
                      {municipiosList
                        ? municipiosList.map((loc) => (
                          <option key={loc.sigla_uf} value={loc.sigla_uf}>
                            {loc.nome_uf}
                          </option>
                        ))
                        : 'Carregando...'}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='city' shrink>Cidade</InputLabel>
                    <Select native {...getFieldProps('city')} id="city" label="Cidade">
                      <option value="0">Selecione um estado primeiro</option>
                      {cidades
                        ? cidades.map((loc) => (
                          <option key={loc.codigo_ibge} value={loc.codigo_ibge}>
                            {loc.nome_municipio}
                          </option>
                        ))
                        : 'Carregando...'}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel>Fuso Horário</InputLabel>
                    <Select native label="Fuso Horário" {...getFieldProps('fuse')}>
                      <option value="America/Sao_Paulo">Horário de Brasília (GMT-3)</option>
                      <option value="America/Noronha">Fernando de Noronha (GMT-2)</option>
                      <option value="America/Manaus">RR, RO, AM, MT e MS (GMT-4)</option>
                      <option value="America/Rio_Branco">Acre e Oeste do AM (GMT-5)</option>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <MaskedTextField
                    fullWidth
                    mask="(99) 9999-9999"
                    type='tel'
                    label="Telefone do restaurante"
                    {...getFieldProps('tel1')}
                    error={Boolean(touched.tel1 && errors.tel1)}
                    helperText={touched.tel1 && errors.tel1}
                  />
                  <MaskedTextField
                    fullWidth
                    mask="(99) 99999-9999"
                    type='tel'
                    label="WhatsApp do responsável"
                    {...getFieldProps('tel2')}
                    error={Boolean(touched.tel2 && errors.tel2)}
                    helperText={touched.tel2 && errors.tel2}
                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Nome Responsável"
                    {...getFieldProps('nameResp')}
                    error={Boolean(touched.nameResp && errors.nameResp)}
                    helperText={touched.nameResp && errors.nameResp}
                  />
                  <TextField
                    fullWidth
                    type="email"
                    label="E-mail responsável"
                    {...getFieldProps('emailResp')}
                    error={Boolean(touched.emailResp && errors.emailResp)}
                    helperText={touched.emailResp && errors.emailResp}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <FormControl fullWidth>
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyD7xGSICQrE46kaUNE9ONguEVmI-xpguyM"
                      selectProps={{
                        selectedAddress,
                        onChange: setSelectedAddress,
                        value: selectedAddress,
                        placeholder: 'Digite o endereço do estabelecimento',
                        noOptionsMessage: () => 'Nenhum endereço encontrado',
                        loadingMessage: () => 'Pesquisando...',
                        styles: {
                          control: (provided) => ({
                            ...provided,
                            border: '1px solid rgba(0, 0, 0, 0.23)',
                            borderRadius: '8px',
                            padding: '0rem 0rem',
                            minHeight: '56px',
                          }),
                          input: (provided) => ({
                            ...provided,
                            padding: '0',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            fontSize: '1rem',
                          }),
                        }
                      }}
                      defaultProps={{}}
                    />
                  </FormControl>
                  <TextField
                    fullWidth
                    type='tel'
                    label="Número"
                    {...getFieldProps('num')}
                    error={Boolean(touched.num && errors.num)}
                    helperText={touched.num && errors.num}
                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                  />
                </Stack>
                <Stack>
                  <TextField
                    style={{ display: 'none' }}
                    fullWidth
                    label="Latitude"
                    placeholder="00.00000"
                    {...getFieldProps('lat')}
                    error={Boolean(touched.lat && errors.lat)}
                    helperText={touched.lat && errors.lat}
                  />
                  <TextField
                    style={{ display: 'none' }}
                    fullWidth
                    label="Longitude"
                    placeholder="00.00000"
                    {...getFieldProps('long')}
                    error={Boolean(touched.long && errors.long)}
                    helperText={touched.long && errors.long}
                  />
                </Stack>
                {errors.afterSubmit && (
                  <Alert sx={{ mb: 2, mt: 2 }} severity="error">
                    {errors.afterSubmit}
                  </Alert>
                )}
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton style={!isDesktop ? { width: "100%", height: '50px', fontSize: '19px' } : { height: '50px', fontSize: '19px', width: '120px' }} type="submit" variant="contained" loading={isSubmitting}>
                    Enviar
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>

      <div>
        <Dialog
          open={open}
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Digite seu endereço correto</DialogTitle>
          <DialogContent style={{ height: '300px' }}>
            <DialogContentText id="alert-dialog-description">
              <Box sx={{ mt: 1, mb: 2 }}>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyD7xGSICQrE46kaUNE9ONguEVmI-xpguyM"
                  selectProps={{
                    selectedAddress,
                    onChange: setSelectedAddress,
                    value: selectedAddress,
                    placeholder: 'Digite o endereço',
                    noOptionsMessage: () => 'Nenhum endereço encontrado',
                    loadingMessage: () => 'Pesquisando...'
                  }}
                  defaultProps={{}}
                />
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </FormikProvider>
  );
}
