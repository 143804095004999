import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
import serialize from '../../utils/serialize';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  infoAdmYear: [],
  infoAdmMonth: [],
  infoAdm: [],
  infoValue: {},
  qntCustomers: 0,
  infoLicOrders: {}
};

const slice = createSlice({
  name: 'dashboardAdm',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getInfoAdmSuccess(state, action) {
      state.isLoading = false;
      state.infoAdm = action.payload;
    },

    getInfoAdmYearSuccess(state, action) {
      state.isLoading = false;
      state.infoAdmYear = action.payload;
    },

    getInfoAdmMonthSuccess(state, action) {
      state.isLoading = false;
      state.infoAdmMonth = action.payload;
    },
    getInfoValueSuccess(state, action) {
      state.isLoading = false;
      state.infoValue = action.payload;
    },
    getQntCustomersSuccess(state, action) {
      state.isLoading = false;
      state.qntCustomers = action.payload;
    },

    getInfoOrdersSuccess(state, action) {
      state.isLoading = false;
      state.infoOrders = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

export function getInfoAdmYear(data) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${api}/info-adm-year`, data);
      dispatch(slice.actions.getInfoAdmYearSuccess(response.data?.result));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getInfoAdmMonth(data) {
  return async (dispatch) => {
    try {
      data = serialize(data);
      const response = await axios.get(`${api}/info-adm-month?${data}`);
      dispatch(slice.actions.getInfoAdmMonthSuccess(response.data.results));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getInfoAdm(data) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${api}/info-dashboard-adm`, data);
      dispatch(slice.actions.getInfoAdmSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getInfoValue(data) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${api}/info-dashboard-values-adm`, data);
      dispatch(slice.actions.getInfoValueSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getQntCustomers(data) {
  return async (dispatch) => {
    try {

      data = serialize(data);
      const response = await axios.get(`${api}/info-dashboard-customers-adm?${data}`);
      dispatch(slice.actions.getQntCustomersSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getInfoOrders(data) {
  return async (dispatch) => {
    try {
      data = serialize(data);
      const response = await axios.get(`${api}/info-adm-orders?${data}`);
      dispatch(slice.actions.getInfoOrdersSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
