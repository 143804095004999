import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  shopkeepers: [],
  shopkeeperList: [],
  responsePost: null
};

const slice = createSlice({
  name: 'shopkeeper',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getShopkeepersSuccess(state, action) {
      state.isLoading = false;
      state.shopkeepers = action.payload;
    },

    // GET MUNICIPIOS
    getMunicipiosSuccess(state, action) {
      state.isLoading = false;
      state.municipiosList = action.payload;
    },

    // DELETE USERS
    deleteShopkeeper(state, action) {
      const deleteShopkeeper = filter(state.shopkeeperList, (shopkeeper) => shopkeeper.id !== action.payload);
      state.shopkeeperList = deleteShopkeeper;
    },

    // GET MANAGE USERS
    getShopkeeperListSuccess(state, action) {
      state.isLoading = false;
      state.shopkeeperList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getShopkeeperList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/shopkeepers`);
      dispatch(slice.actions.getShopkeeperListSuccess(response.data.shopkeepers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeShopkeeper(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/shopkeepers`, values);
      dispatch(slice.actions.getShopkeeperListSuccess(response.data.shopkeepers));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateShopkeeper(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/shopkeepers`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteShopkeeper(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/shopkeepers/${newId}`);
      dispatch(slice.actions.deleteShopkeeper(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
