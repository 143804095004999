import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  brand: [],
  brandsList: [],
};

const slice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getBrandListSuccess(state, action) {
      state.isLoading = false;
      state.brandsList = action.payload;
    },

    deleteBrand(state, action) {
      const deleteBrand = filter(state.brandsList, (brand) => brand._id !== action.payload);
      state.brandsList = deleteBrand;
    },

    getBrandByIdSuccess(state, action) {
      state.isLoading = false;
      state.brand = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getBrandList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/brands`);
      dispatch(slice.actions.getBrandListSuccess(response.data.brands.docs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBrandById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/brands/${id}`);
      dispatch(slice.actions.getBrandByIdSuccess(response.data.brand));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeBrand(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/brands`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateBrand(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/brands`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteBrand(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/brands/${newId}`);
      dispatch(slice.actions.deleteBrand(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
