import { TableRow, TableCell, Skeleton } from '@mui/material';

export default function TableSkeleton({ showTotalPedido = true, ...other }) {
  return (
    <TableRow {...other}>
      <TableCell>
        <Skeleton variant="rectangular" width={40} height={40} sx={{ borderRadius: 1 }} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={20} height={20} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={60} height={20} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={150} height={20} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={80} height={20} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={100} height={20} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={80} height={20} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={80} height={20} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={120} height={20} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={120} height={20} />
      </TableCell>
      {showTotalPedido && (
        <TableCell>
          <Skeleton variant="text" width={100} height={20} />
        </TableCell>
      )}
      <TableCell>
        <Skeleton variant="circular" width={30} height={30} />
      </TableCell>
    </TableRow>
  );
}
