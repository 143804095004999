// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  firstAccess: path(ROOTS_AUTH, '/first-access'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  brand: {
    root: path(ROOTS_DASHBOARD, '/brand'),
    list: path(ROOTS_DASHBOARD, '/brand/list'),
    editById: path(ROOTS_DASHBOARD, `/brand/reece-chung/edit`),
    new: path(ROOTS_DASHBOARD, '/brand/new')
  },
  reports: path(ROOTS_DASHBOARD, '/reports'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app')
  },
  manual: {
    root: path(ROOTS_DASHBOARD, '/manual')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    permissions: path(ROOTS_DASHBOARD, '/user/permissions'),
    asaas: path(ROOTS_DASHBOARD, '/user/asaas')
  },
  licensed: {
    root: path(ROOTS_DASHBOARD, '/licensed'),
    list: path(ROOTS_DASHBOARD, '/licensed/list'),
    newUser: path(ROOTS_DASHBOARD, '/licensed/new'),
    permissions: path(ROOTS_DASHBOARD, '/licensed/permissions'),
    editById: path(ROOTS_DASHBOARD, `/licensed/reece-chung/edit`),
    ranking: path(ROOTS_DASHBOARD, `/insight/top-licenseds`)
  },
  financial: {
    root: path(ROOTS_DASHBOARD, '/financial'),
    coupons: path(ROOTS_DASHBOARD, '/financial/coupons'),
    distributor: path(ROOTS_DASHBOARD, '/financial/distributor'),
    geral: path(ROOTS_DASHBOARD, '/financial/geral')
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/category'),
    list: path(ROOTS_DASHBOARD, '/category/list'),
    newUser: path(ROOTS_DASHBOARD, '/category/new'),
    editById: path(ROOTS_DASHBOARD, `/category/reece-chung/edit`),
    order: path(ROOTS_DASHBOARD, '/category/order')
  },
  heatmap: {
    root: path(ROOTS_DASHBOARD, '/heatmap')
  },
  cart: {
    abandonned: path(ROOTS_DASHBOARD, '/cart/abandonned')
  },
  district: {
    root: path(ROOTS_DASHBOARD, '/district'),
    list: path(ROOTS_DASHBOARD, '/district/list'),
    new: path(ROOTS_DASHBOARD, '/district/new'),
    editById: path(ROOTS_DASHBOARD, `/district/reece-chung/edit`)
  },
  contentCategory: {
    root: path(ROOTS_DASHBOARD, '/content-category'),
    list: path(ROOTS_DASHBOARD, '/content-category/list'),
    new: path(ROOTS_DASHBOARD, '/content-category/new'),
    editById: path(ROOTS_DASHBOARD, `/content-category/reece-chung/edit`)
  },
  content: {
    root: path(ROOTS_DASHBOARD, '/content'),
    admin: path(ROOTS_DASHBOARD, '/content/admin'),
    list: path(ROOTS_DASHBOARD, '/content/list'),
    new: path(ROOTS_DASHBOARD, '/content/new'),
    editById: path(ROOTS_DASHBOARD, `/content/reece-chung/edit`),
    view: (title) => path(ROOTS_DASHBOARD, `/content/${title}`)
  },
  marketingCategory: {
    root: path(ROOTS_DASHBOARD, '/marketing-category'),
    list: path(ROOTS_DASHBOARD, '/marketing-category/list'),
    new: path(ROOTS_DASHBOARD, '/marketing-category/new'),
    editById: path(ROOTS_DASHBOARD, `/marketing-category/reece-chung/edit`)
  },
  marketing: {
    root: path(ROOTS_DASHBOARD, '/marketing'),
    admin: path(ROOTS_DASHBOARD, '/marketing/admin'),
    list: path(ROOTS_DASHBOARD, '/marketing/list'),
    new: path(ROOTS_DASHBOARD, '/marketing/new'),
    editById: path(ROOTS_DASHBOARD, `/marketing/reece-chung/edit`),
    view: (title) => path(ROOTS_DASHBOARD, `/marketing/${title}`)
  },
  contract: {
    root: path(ROOTS_DASHBOARD, '/contract'),
    admin: path(ROOTS_DASHBOARD, '/contract/admin'),
    list: path(ROOTS_DASHBOARD, '/contract/list'),
    new: path(ROOTS_DASHBOARD, '/contract/new'),
    editById: path(ROOTS_DASHBOARD, `/contract/reece-chung/edit`)
  },
  productCategory: {
    root: path(ROOTS_DASHBOARD, '/product-category'),
    list: path(ROOTS_DASHBOARD, '/product-category/list'),
    new: path(ROOTS_DASHBOARD, '/product-category/new'),
    editById: path(ROOTS_DASHBOARD, `/model-product/reece-chung/edit`)
  },
  shop: {
    root: path(ROOTS_DASHBOARD, '/shop')
  },
  productV2: {
    list: path(ROOTS_DASHBOARD, '/productV2/list'),
    new: path(ROOTS_DASHBOARD, '/productV2/new'),
    listModels: path(ROOTS_DASHBOARD, '/productV2/list-models'),
    newModel: path(ROOTS_DASHBOARD, '/productV2/new-model'),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    new: path(ROOTS_DASHBOARD, '/product/new'),
    listModels: path(ROOTS_DASHBOARD, '/product/list-models'),
    models: path(ROOTS_DASHBOARD, '/model-product/all'),
  },
  productModel: {
    root: path(ROOTS_DASHBOARD, '/model-product'),
    list: path(ROOTS_DASHBOARD, '/model-product/list'),
    new: path(ROOTS_DASHBOARD, '/model-product/new'),
    editById: path(ROOTS_DASHBOARD, `/model-product/reece-chung/edit`)
  },
  request: {
    root: path(ROOTS_DASHBOARD, '/request'),
    list: path(ROOTS_DASHBOARD, '/request/list'),
    listAll: path(ROOTS_DASHBOARD, '/request/all-list')
  },
  config: {
    root: path(ROOTS_DASHBOARD, '/config')
  },
  whatsapp: {
    connect: path(ROOTS_DASHBOARD, '/whatsapp/connect'),
    pay: path(ROOTS_DASHBOARD, '/whatsapp/pay')
  },
  payment: {
    root: path(ROOTS_DASHBOARD, '/payment/my-account'),
    history: path(ROOTS_DASHBOARD, '/payment/history'),
    online: path(ROOTS_DASHBOARD, '/payment/online')
  },
  coupon: {
    root: path(ROOTS_DASHBOARD, '/coupon'),
    list: path(ROOTS_DASHBOARD, '/coupon/list'),
    shop: path(ROOTS_DASHBOARD, '/coupon/shop'),
    newTemplate: path(ROOTS_DASHBOARD, '/coupon/new-template'),
    new: path(ROOTS_DASHBOARD, '/coupon/new')
  },
  fidelity: {
    root: path(ROOTS_DASHBOARD, '/fidelity'),
    list: path(ROOTS_DASHBOARD, '/fidelity/list'),
    new: path(ROOTS_DASHBOARD, '/fidelity/new'),
    details: path(ROOTS_DASHBOARD, '/fidelity/details')
  },
  restaurant: {
    root: path(ROOTS_DASHBOARD, '/restaurant'),
    list: path(ROOTS_DASHBOARD, '/restaurant/list'),
    admin: path(ROOTS_DASHBOARD, '/restaurant/admin'),
    newUser: path(ROOTS_DASHBOARD, '/restaurant/new'),
    editById: path(ROOTS_DASHBOARD, `/restaurant/reece-chung/edit`),
    edit: path(ROOTS_DASHBOARD, `/restaurant/edit`),
    opening: path(ROOTS_DASHBOARD, `/restaurant/opening`),
    rating: path(ROOTS_DASHBOARD, `/restaurant/rating`)
  },
  deliveryFee: {
    list: path(ROOTS_DASHBOARD, '/delivery-fee/list')
  },
  customer: {
    list: path(ROOTS_DASHBOARD, '/customer/list')
  },
  accounts: {
    list: path(ROOTS_DASHBOARD, '/accounts/list')
  },
  report: {
    list: path(ROOTS_DASHBOARD, '/report/list'),
    history: path(ROOTS_DASHBOARD, '/report/history')
  },
  deliveryMan: {
    root: path(ROOTS_DASHBOARD, '/delivery-man'),
    new: path(ROOTS_DASHBOARD, '/delivery-man/new'),
    list: path(ROOTS_DASHBOARD, '/delivery-man/list'),
    editById: path(ROOTS_DASHBOARD, `/delivery-man/reece-chung/edit`)
  },
  log: {
    list: path(ROOTS_DASHBOARD, '/log/list')
  },
  ecommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce')
  },
  import: {
    root: path(ROOTS_DASHBOARD, '/import')
  },
  push: {
    root: path(ROOTS_DASHBOARD, '/push'),
    new: path(ROOTS_DASHBOARD, '/push/new'),
    list: path(ROOTS_DASHBOARD, '/push/list')
  },
  banner: {
    root: path(ROOTS_DASHBOARD, '/banner'),
    new: path(ROOTS_DASHBOARD, '/banner/new'),
    list: path(ROOTS_DASHBOARD, '/banner/list'),
    listLic: path(ROOTS_DASHBOARD, '/banner/licensed/list')
  },
  attendance: {
    root: path(ROOTS_DASHBOARD, '/attendance'),
    all: path(ROOTS_DASHBOARD, '/attendance/all'),
    new: path(ROOTS_DASHBOARD, '/attendance/new'),
    list: path(ROOTS_DASHBOARD, '/attendance/list')
  },
  campaigns: {
    root: path(ROOTS_DASHBOARD, '/campaigns'),
    list: path(ROOTS_DASHBOARD, '/campaigns/list'),
    participe: path(ROOTS_DASHBOARD, '/campaigns/participe'),
    new: path(ROOTS_DASHBOARD, '/campaigns/new')
  },
  grid: {
    root: path(ROOTS_DASHBOARD, '/grid'),
    list: path(ROOTS_DASHBOARD, '/grid/list'),
    new: path(ROOTS_DASHBOARD, '/grid/new'),
    editById: path(ROOTS_DASHBOARD, `/grid/reece-chung/edit`)
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
