import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  contentList: [],
  responsePost: null,
  content: {}
};

const slice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getContentsSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    deleteContent(state, action) {
      const deleteContent = filter(state.contentList, (category) => category._id !== action.payload);
      state.contentList = deleteContent;
    },

    getContentListSuccess(state, action) {
      state.isLoading = false;
      state.contentList = action.payload;
    },

    getContent(state, action) {
      state.isLoading = false;
      state.content = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getContentList(type = '') {
  let filter = '';
  if (type) filter = `?parent=${type}`;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/contents${filter}`);
      dispatch(slice.actions.getContentListSuccess(response.data.contents));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContent(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/contents/${id}`);
      dispatch(slice.actions.getContent(response.data.content));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeContent(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/contents`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateContent(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/contents`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteContent(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/contents/${newId}`);
      dispatch(slice.actions.deleteContent(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
