import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  deliveryMen: [],
  deliveryManList: [],
  responsePost: null
};

const slice = createSlice({
  name: 'deliveryMan',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDeliveryMansSuccess(state, action) {
      state.isLoading = false;
      state.deliveryMen = action.payload;
    },

    deleteDeliveryMan(state, action) {
      const deleteDeliveryMan = filter(state.deliveryManList, (deliveryMan) => deliveryMan._id !== action.payload);
      console.log(deleteDeliveryMan);
      state.deliveryManList = deleteDeliveryMan;
    },

    getDeliveryManListSuccess(state, action) {
      state.isLoading = false;
      state.deliveryManList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getDeliveryManList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/delivery-man`);
      dispatch(slice.actions.getDeliveryManListSuccess(response.data.deliveryMen));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeDeliveryMan(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/delivery-man`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function storeDeliveryManUnauth(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/delivery-man/unauth`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateDeliveryMan(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/delivery-man`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteDeliveryMan(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/delivery-man/${newId}`);
      dispatch(slice.actions.deleteDeliveryMan(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
