import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getContractUserPayment, updateContract } from '../../../redux/slices/contract';
import Iconify from '../../../components/Iconify';
import Markdown from '../../../components/Markdown';
import useAuth from '../../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../../routes/paths';
import OnlinePayStatus from './OnlinePayStatus';
import banner from '../../../assets/bannerOnline.jpg';

function OnlinePayHome({ perm }) {
  const { contractListUserPayment } = useSelector((state) => state.contract);
  const [modals, setModals] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateProfile } = useAuth();

  useEffect(() => {
    const updateProfileAsync = async () => {
      setLoading(true);
      try {
        await updateProfile();
        if (user._id) {
          dispatch(getContractUserPayment(user._id));
        }
      } finally {
        setLoading(false);
      }
    };
    updateProfileAsync();
  }, []);


  const handleOpenContract = () => {
    if (contractListUserPayment.length === 0) {
      navigate(PATH_DASHBOARD.user.asaas);
      return;
    }
    if (contractListUserPayment && Array.isArray(contractListUserPayment)) {
      const newModals = Array.from({ length: contractListUserPayment.length }, () => ({ open: true, accept: false }));
      setModals(newModals);
    }
  };

  const handleAgreeChange = (index) => (event) => {
    const newModals = [...modals];
    newModals[index].accept = event.target.checked;
    setModals(newModals);
  };

  const handleAcceptContract = (index) => () => {
    const newModals = [...modals];
    newModals[index].open = false;
    setModals(newModals);

    const values = {
      action: 'add',
      _id: contractListUserPayment[index]._id,
      user: user._id
    };
    dispatch(updateContract(values));
    navigate(PATH_DASHBOARD.user.asaas);
  };

  const handleClose = (index) => (event) => {
    const newModals = [...modals];
    newModals[index].open = false;
    setModals(newModals);
  };

  const cardStyles = {
    backgroundColor: 'rgb(247 247 247 / 72%)',
    borderRadius: '10px',
    margin: '20px',
    padding: '20px'
  };

  const cardHeaderStyles = {
    display: 'flex', alignItems: 'center',
    marginBottom: '20px'
  };

  const cardIconStyles = {
    height: '20px',
    marginRight: '10px',
    width: '20px'
  };

  const cardIconStylesNu = {
    height: '35px',
    marginRight: '10px',
    width: '35px'
  };

  const headerTextStyles = {
    fontSize: '17px',
    margin: '0px',
    fontWeight: 500,
    color: '#4e4e4e'
  };

  const bodyTextStyles = {
    fontSize: '18px',
    margin: '0',
    color: 'gray',
    textAlign: 'left'
  };

  const CreditCardIcon = 'https://cdn-icons-png.flaticon.com/512/4341/4341764.png';
  const PixIcon = 'https://devtools.com.br/img/pix/logo-pix-png-icone-520x520.png';
  const NuIcon = 'https://logodownload.org/wp-content/uploads/2023/08/nupay-logo-0.png';

  const layoutT = (
    <div >
      <div
      >
        <img
          alt=""
          style={{ borderRadius: '17px', marginBottom: 20 }}
          src={banner}
        />
      </div>
      <div
        style={{ marginTop: '-10px' }}
      >
        <h3>Com o pagamento online do JÃO é dinheiro no bolso!
        </h3>
        <p style={{ marginTop: 10, color: '#4a4a4a' }}>30% dos consumidores já preferem essa forma de pagamento no aplicativo.</p>
        <p style={{ marginTop: 10, color: '#4a4a4a' }}>Não perca essa chance de vender mais!</p>
        <br />
        <ul style={{ fontSize: '18px', listStyle: 'none', marginLeft: '0px' }}>
          <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <Icon color='#FFC107' icon="uil-check-circle" width="25" style={{ marginRight: '8px' }} />
            Pix ativo automaticamente.
          </li>
          <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <Icon color='#FFC107' icon="uil-check-circle" width="25" style={{ marginRight: '8px' }} />
            Receba o dinheiro direto na sua conta.
          </li>
          <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <Icon color='#FFC107' icon="uil-check-circle" width="25" style={{ marginRight: '8px' }} />
            Armazene dados de consumo e fidelize clientes.
          </li>
        </ul>

        <div style={{ display: 'flex', marginLeft: '-20px' }}>
          <div style={cardStyles} className="card">
            <div style={cardHeaderStyles} className="card-header">
              <img style={cardIconStyles} src={CreditCardIcon} alt="" />
              <h3 style={headerTextStyles}>Cartão de Crédito</h3>
            </div>
            <div style={bodyTextStyles} className="card-body">
              <p>
                Taxa: <b> 2,93%</b>
              </p>
              <p>
                Receba em: <b>30 dias</b>
              </p>
              <br />
              <img src="https://maquinetavirtual.com.br/img/bandeiras.png" width={150} alt="" />
            </div>
          </div>
          <div style={cardStyles} className="card">
            <div style={cardHeaderStyles} className="card-header">
              <img style={cardIconStyles} src={PixIcon} alt="" />
              <h3 style={headerTextStyles}>PIX</h3>
            </div>
            <div style={bodyTextStyles} className="card-body">
              <p>
                Taxa: <b> R$ 0,59</b>
              </p>
              <p>
                Receba em: <b>1 dia útil</b>
              </p>
              <br />
              <img src="https://i.pinimg.com/originals/01/9a/2d/019a2d16277d159cd2ef1298a4c60ab8.png" width={50} alt="" />
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: 'rgb(247 247 247 / 72%)', borderRadius: '10px', padding: '20px' }} className="card">
          <div style={cardHeaderStyles} className="card-header">
            <img style={cardIconStylesNu} src={NuIcon} alt="" />
            <h3 style={headerTextStyles}>NuPay</h3>
          </div>
          <div style={bodyTextStyles} className="card-body">
            <p>
              Taxa: <b> 3,90%</b>
            </p>
            <p>
              Receba em: <b>1 dia útil</b>
            </p>
          </div>
        </div>
        <br />
      </div>
    </div>
  );

  const activeButton = (
    <Button
      style={{ position: 'absolute', right: '40px' }}
      onClick={() => handleOpenContract()}
      variant="contained"
      id="addGroup"
    >
      Ativar agora
    </Button>
  );

  const noPerm = (
    <><Button
      style={{ textTransform: 'none', background: '#ea032e', color: 'white' }}
    >
      Indisponível em sua região no momento
    </Button>
      <p style={{ fontSize: 11, color: 'gray' }}>**Solicite ajuda no chat do suporte para verificar a possível disponibilidade</p>
    </>
  );

  return (
    <>
      {!loading ? (<>{!user.parent ? (
        <>
          {
            user.tunaStatus && !perm ? (<OnlinePayStatus />) : (
              <> {layoutT}

                {!perm ? activeButton : noPerm}

                {contractListUserPayment.map((contract, index) => (
                  <div key={index}>
                    <Dialog
                      maxWidth="lg"
                      open={modals[index]?.open}
                      onClose={() => handleAgreeChange(index)({ target: { checked: false } })}
                    >
                      <DialogActions
                        sx={{
                          zIndex: 9,
                          padding: '12px !important',
                          boxShadow: (theme) => theme.customShadows.z8
                        }}
                      >
                        <Tooltip title="Close">
                          <IconButton color="inherit" onClick={handleClose(index)}>
                            <Iconify icon="eva:close-fill" />
                          </IconButton>
                        </Tooltip>
                      </DialogActions>
                      <DialogTitle style={{ fontSize: 25, backgroundColor: '#fff' }}>{contract.title}</DialogTitle>
                      <DialogContent style={{ width: '1200px', height: '800px' }}>
                        <Markdown style={{ padding: '20px 20px' }} children={contract.text} />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </DialogContent>
                      <DialogActions
                        style={{
                          position: 'absolute',
                          bottom: '0px',
                          left: '0px',
                          backgroundColor: '#f1f1f1',
                          width: '100%'
                        }}
                      >
                        <FormControlLabel
                          control={<Checkbox checked={modals[index]?.accept} onChange={handleAgreeChange(index)} />}
                          label={
                            <Typography variant="body1" style={{ fontSize: '17px' }}>
                              Li e concordo com os termos
                            </Typography>
                          }
                        />
                        <Button disabled={!modals[index]?.accept} onClick={handleAcceptContract(index)} variant="contained">
                          Aceitar termos
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                ))}</>
            )
          }
        </>
      ) : (
        <Alert severity="error" sx={{ my: 1 }}>
          Apenas o lojista ou licenciado principal pode criar conta.
        </Alert>
      )}</>) : (

        <Stack style={{
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 120
        }}>
          <CircularProgress size={60} />
          <p style={{ marginTop: 15, fontWeight: 'bold' }}>Carregando suas informações</p>
        </Stack>

      )}
    </>
  );

}

export default OnlinePayHome
