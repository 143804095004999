import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../config';

const initialState = {
  isLoading: false,
  error: false,
  grid: [],
  gridsList: [],
};

const slice = createSlice({
  name: 'grid',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET GRIDS
    getGridListSuccess(state, action) {
      state.isLoading = false;
      state.gridsList = action.payload;
    },

    // GET GRID
    getGridByIdSuccess(state, action) {
      state.isLoading = false;
      state.grid = action.payload;
    },

    // DELETE GRID
    deleteGrid(state, action) {
      state.gridsList = state.gridsList.filter(grid => grid._id !== action.payload);
    }
  }
});

export default slice.reducer;

export function getGridList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/grids`);
      dispatch(slice.actions.getGridListSuccess(response.data.grids));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGridById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/grids/${id}`);
      dispatch(slice.actions.getGridByIdSuccess(response.data.grid));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function storeGrid(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/grids`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function updateGrid(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(6);
      const response = await axios.put(`${api}/grids`, values);
      console.log(7);
      return response.data;
    } catch (error) {
      console.log('ERRORRRRR',error);
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function deleteGrid(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/grids/${id}`);
      dispatch(slice.actions.deleteGrid(id));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
} 